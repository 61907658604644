import React from "react";
import {Link} from "@mui/material";
import phone from "../../../images/truba.png"

const Phone: React.FC = () => {

    return (
        <Link
            className={'phone'}
            underline="none"
            color="inherit"
            href={"tel:+7 3452 500-617"}
        >
            <img
                src={phone}
                alt={"phone"}
            />
            <div className={'phone__number'}>
                3452 500-617
            </div>
        </Link>
    )
}

export default Phone;