import React from "react";
import {Box} from "@mui/material";

const PhoneLink: React.FC = () => {
    return (
        <Box
            className={'footer__link'}
        >
            Единый контактный центр: +7 3452 500-617
        </Box>
    )
}

export default PhoneLink;