import React from "react";
import {Grid, Stack} from "@mui/material";
import EmptyDataCustom from "../../Customs/EmptyDataCustom";
import {useSnackbar} from 'notistack';
import {CustomFilter} from "../../../helpers";
import CircularLoader from "../../CircularLoader";
import {fetchSpecialitiesList} from "../../../store/action-creators/specialitiesList";
import {useDispatch} from "react-redux";
import {useTypedSelector} from "../../../hooks/useTypedSelector";
import DirectionItem from "./Direction";
import Search from "../../Search";
import {useNavigateAppointment} from "../hooks/useNavigateAppointment";
import {AppointmentContext} from "../Appointment";

const Directions = () => {
    const {enqueueSnackbar} = useSnackbar();

    const navigateAppointment = useNavigateAppointment();
    const dispatch = useDispatch()
    const specialitiesList = useTypedSelector(state => state.specialitiesList)

    // eslint-disable-next-line
    const [selectedTab, setSelectedTab] = React.useContext(AppointmentContext);

    const [selectedSpeciality, setSelectedSpeciality] = React.useState(sessionStorage.getItem('selectedDirection') || '*')

    React.useLayoutEffect(() => {
        setSelectedTab('directions')
        sessionStorage.setItem('selectedTab', 'directions');
    })

    const selectDirection = (name: string, id: string) => {
        sessionStorage.setItem("selectedDirection", id);
        setSelectedSpeciality(id)

        navigateAppointment.navigateSpecialists();
    }

    const [searchString, setSearchString] = React.useState('');

    React.useEffect(() => {
        dispatch(fetchSpecialitiesList(enqueueSnackbar))
    }, [dispatch, enqueueSnackbar])

    if (specialitiesList.loading) {
        return (
            <CircularLoader/>
        )
    } else if (!specialitiesList.specialties.length) {
        return (
            <EmptyDataCustom/>
        )
    } else {
        return (
            <div>
                <Stack alignItems={"center"} justifyItems={"center"}>
                    <Search setSearchString={setSearchString}/>
                </Stack>
                <Grid sx={{flexGrow: 1}} container spacing={3} mt={3}>
                    {CustomFilter(searchString, specialitiesList.specialties).map((specialty, index) => (
                        <DirectionItem
                            key={index}
                            specialty={specialty}
                            selectSpeciality={selectDirection}
                            selectedSpeciality={selectedSpeciality}
                        />
                    ))}
                </Grid>
            </div>
        )
    }
};

export default Directions;