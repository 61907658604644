import React from "react";
import {IconButton, Tooltip} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {useOpenedTooltip} from "../Controller";
import {PersonalMenu} from "../../index";

const MenuDesktop: React.FC = () => {

    const {anchorEl, handleClick, handleClose} = useOpenedTooltip()

    if (localStorage.getItem("token")) {
        return (
            <div className={'menu__desktop'}>
                <Tooltip title="Меню" arrow placement="bottom">
                    <IconButton onClick={handleClick}>
                        <MoreVertIcon/>
                    </IconButton>
                </Tooltip>
                <PersonalMenu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    handleClose={handleClose}
                />
            </div>
        )
    } else {
        return (
            <></>
        )
    }
}

export default MenuDesktop;