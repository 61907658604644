import $api from "../../../http";
import {Dispatch} from "redux";
import {DoctorsListAction, DoctorsListActionTypes} from "../../../types/Store/doctorsList";
import {BookingState} from "../../../types/Doctor";

export const fetchDoctorsList = () => {
    return async (dispatch: Dispatch<DoctorsListAction>) => {
        try {
            dispatch({type: DoctorsListActionTypes.FETCH_DOCTORS_LIST});

            const specId = sessionStorage.getItem("selectedDirection") || '*';
            const misId = sessionStorage.getItem("selectedCenter") || '*';
            const response = await $api.get(`arbitailo/doctors?specId=${specId}&misId=${misId}`);

            if (response.status === 200) {
                dispatch({
                    type: DoctorsListActionTypes.FETCH_DOCTORS_LIST_SUCCESS,
                    payload: response.data
                });
            } else {
                dispatch({
                    type: DoctorsListActionTypes.FETCH_DOCTORS_LIST_ERROR
                });
            }
        } catch (error) {
            console.error(error);

            dispatch({ type: DoctorsListActionTypes.FETCH_DOCTORS_LIST_ERROR });
        }
    }
}

export const handleChangeBookingState = (bookingStateData: BookingState) => {
    return async (dispatch: Dispatch<DoctorsListAction>) => {
        try {
            if (bookingStateData.reserved && !bookingStateData.used) {
                dispatch({
                    type: DoctorsListActionTypes.PROCESS_NEW_BOOKING_STATE,
                    payload: bookingStateData
                });
            } else if (bookingStateData.reserved && bookingStateData.used) {
                const response = await $api.get(`arbitailo/doctors?specId=${sessionStorage.getItem("specId")}&misId=${sessionStorage.getItem("misId")}`);

                if (response.data) {
                    dispatch({
                        type: DoctorsListActionTypes.REFRESH_BOOKING_STATE,
                        payload: response.data
                    });
                }
            } else {
                dispatch({
                    type: DoctorsListActionTypes.REMOVE_BOOKING_STATE,
                    payload: bookingStateData
                });
            }
        } catch (error) {
            console.error(error);
        }
    }
}