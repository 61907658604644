import React from "react";
import {Patient} from "../../../types/Patient";
import {Box, IconButton, Tooltip} from "@mui/material";
import Avatar from "@mui/material/Avatar";
import {useChangePatient} from "../../../hooks/Patient";

interface ViewPatientsProps {
    patients: Array<Patient>;
    selectedPatient: Patient;
}

const ViewPatients: React.FC<ViewPatientsProps> = (
    {
        patients,
        selectedPatient,
    }
) => {
    const handleChangeAvatar = useChangePatient()

    return (
        <div className={'view__patients'}>
            {patients.map((patient, index) => (
                <Tooltip key={index}
                         title={`${patient.lastName} ${patient.firstName} ${patient.fatherName ? patient.fatherName : ""}`}
                         arrow placement="bottom">
                    <IconButton sx={{
                        "&.Mui-disabled": {
                            pointerEvents: "auto"
                        }
                    }} disabled={selectedPatient.id === patient.id}
                                onClick={() => handleChangeAvatar(patient)}
                    >
                        <Box
                            sx={selectedPatient.id === patient.id ? {
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                border: 2,
                                borderStyle: "dashed",
                                borderRadius: "50%",
                                p: 0.1,
                            } : {}}
                        >
                            <Avatar
                                sx={{backgroundColor: selectedPatient.id === patient.id ? "#666666" : "#BDBDBD"}}>
                                {patient.lastName[0] + patient.firstName[0]}
                            </Avatar>
                        </Box>
                    </IconButton>
                </Tooltip>
            ))}
        </div>
    )
}

export default ViewPatients;