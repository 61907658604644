import React from "react";
import {MenuItemProps} from "./types";
import {ListItemIcon, MenuItem} from "@mui/material";
import {Ballot} from "@mui/icons-material";
import {useHistory} from "react-router-dom";
import {RouteNames} from "../../router";

const AppointmentsItem: React.FC<MenuItemProps> = (
    {
        handleCloseParent
    }
) => {
    const history = useHistory()

    const handleClick = () => {
        history.push(RouteNames.PERSONAL_CABINET)
        handleCloseParent()
    }

    return (
        <MenuItem onClick={handleClick}>
            <ListItemIcon>
                <Ballot/>
            </ListItemIcon>
            Мои записи
        </MenuItem>
    )
}

export default AppointmentsItem;