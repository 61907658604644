import React from "react";
import {InfoAboutDoctorProps} from "./types";
import Avatar from "@mui/material/Avatar";
import {Stack, Typography} from "@mui/material";

const InfoAboutDoctor: React.FC<InfoAboutDoctorProps> = ({doctor}) => {
    return (
        <Stack className={'info-about-doctor'} direction="row" spacing={3} alignItems="center">
            <Avatar className={'doctor-avatar'} variant="square" src={doctor.photo!} alt={"avatar"}/>
            <div>
                <Stack direction="row" spacing={1}>
                    <Typography variant="h5">
                        {doctor.fullName.split(" ")[0]}
                    </Typography>
                    <Typography variant="h5" component="div">
                        {doctor.fullName.split(" ")[1][0]}.
                    </Typography>
                    <Typography variant="h5">
                        {doctor.fullName.split(" ")[2][0]}.
                    </Typography>

                </Stack>
                <Typography color={"text.secondary"} fontSize={14}>
                    категория: {doctor.category}
                </Typography>
                <Typography className={"specialty-text"} color={"text.secondary"} fontSize={14}>
                    специальность: {doctor.specialtyName}
                </Typography>
                <Stack>
                    <Typography sx={{fontWeight: "bold"}} color="text.secondary">
                        {doctor.misname}
                    </Typography>
                    <Typography fontSize={12} color="text.secondary">
                        {doctor.misaddress}
                    </Typography>
                </Stack>
            </div>
        </Stack>
    )
}

export default InfoAboutDoctor;