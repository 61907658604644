import React from "react";
import {Link} from "@mui/material";

const TermsOfUseLink: React.FC = () => {
    return (
        <Link
            className={'footer__link'}
            href={"/main/terms-of-use"}
        >
            Пользовательское соглашение
        </Link>
    )
}

export default TermsOfUseLink;