export interface OtherDocument {
    id: number;
    fileName: string;
    mimeType: string;
    remoteCreationTime: string;
}

export enum OtherDocumentsActionTypes {
    FETCH_OTHER_DOCUMENTS = "FETCH_OTHER_DOCUMENTS",
    FETCH_OTHER_DOCUMENTS_SUCCESS =  "FETCH_OTHER_DOCUMENTS_SUCCESS",
    FETCH_OTHER_DOCUMENTS_ERROR = "FETCH_OTHER_DOCUMENTS_ERROR",
}

type FetchOtherDocumentsAction = {
    type: OtherDocumentsActionTypes.FETCH_OTHER_DOCUMENTS,
}

type FetchOtherDocumentsSuccessAction = {
    type: OtherDocumentsActionTypes.FETCH_OTHER_DOCUMENTS_SUCCESS,
    payload: Array<OtherDocument>
}

type FetchOtherDocumentsErrorAction = {
    type: OtherDocumentsActionTypes.FETCH_OTHER_DOCUMENTS_ERROR,
}

export type OtherDocumentsAction = FetchOtherDocumentsAction |
    FetchOtherDocumentsSuccessAction | FetchOtherDocumentsErrorAction

export type OtherDocumentsState = {
    docs: Array<OtherDocument>,
    loading: boolean,
}