import {Dispatch} from "redux";
import {SpecialtiesListAction, SpecialtiesListActionType} from "../../../types/Store/specialtiesList";
import $api from "../../../http";
import {EnqueueSnackbar} from "../../../types/Global/Global";
import axios from "axios";

const fetchSpecialitiesList = (enqueueSnackbar: EnqueueSnackbar) => {
    return async (dispatch: Dispatch<SpecialtiesListAction>) => {
        try {
            dispatch({
                type: SpecialtiesListActionType.FETCH_SPECIALITIES_LIST
            })

            const misId = sessionStorage.getItem('selectedCenter') || '*'
            const response = await $api.get(`arbitailo/specialities?misId=${misId}`)

            if (response.status === 200) {
                dispatch({
                    type: SpecialtiesListActionType.FETCH_SPECIALITIES_LIST_SUCCESS,
                    payload: response.data
                })
            } else {
                dispatch({
                    type: SpecialtiesListActionType.FETCH_SPECIALITIES_LIST_ERROR
                })
                enqueueSnackbar(response.data, {variant: "error"})
            }
        } catch (error) {
            console.error(error)

            dispatch({
                type: SpecialtiesListActionType.FETCH_SPECIALITIES_LIST_ERROR
            })

            if (axios.isAxiosError(error)) {
                enqueueSnackbar(error.response?.data, {variant: "error"})
            }
        }
    }
}

export default fetchSpecialitiesList;