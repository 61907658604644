import {useHistory} from "react-router-dom";
import {AppointmentRoutes} from "../../../router";

export const useNavigateAppointment = () => {
    const history = useHistory();

    const navigateCenters = () => {
        sessionStorage.setItem('selectedCenter', '*');
        sessionStorage.setItem('selectedDirection', '*');
        history.push(AppointmentRoutes.CENTERS);
    }

    const navigateDirections = () => {
        sessionStorage.setItem('selectedDirection', '*');
        history.push(AppointmentRoutes.DIRECTIONS);
    }

    const navigateSpecialists = () => {
        history.push(AppointmentRoutes.SPECIALISTS);
    }

    return {
        navigateCenters,
        navigateDirections,
        navigateSpecialists,
    }
}