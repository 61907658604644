import React from "react";
import {
    Box,
    IconButton, Stack,
    SwipeableDrawer
} from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';
import MenuList from "./components/MenuList";
import './style.scss'
import {PhoneLink, PrivacyPolicyLink, SocialLink, TermsOfUseLink} from "../../../Footer/components";

const BurgerMenuMobile: React.FC = () => {

    const [open, setOpen] = React.useState<boolean>(false);

    const handleClick = () => {
        setOpen(!open);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div>
            <IconButton className={'burger__button'} onClick={handleClick}>
                <MenuIcon/>
            </IconButton>
            <SwipeableDrawer
                anchor={'right'}
                open={open}
                onClose={handleClose}
                onOpen={handleClick}
            >
                <Box
                    className={'burger__menu'}
                    role="presentation"
                >
                    <MenuList
                        handleCloseParent={handleClose}
                    />
                    <Box className={'footer__burger'}>
                        <Stack spacing={0.5}>
                            <PrivacyPolicyLink/>
                            <TermsOfUseLink/>
                        </Stack>
                        <Stack spacing={2} direction={"row"} sx={{mt: 2}}>
                            <PhoneLink/>
                            <SocialLink/>
                        </Stack>
                    </Box>
                </Box>

            </SwipeableDrawer>
        </div>
    )
}

export default BurgerMenuMobile;