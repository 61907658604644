import * as _ from "lodash";

export type Filter = <T>(searchString: string, elementList: Array<T>) => Array<T>;

const CustomFilter: Filter = (searchString = "", elementList) => {
    if (elementList.length > 0) {
        // @ts-ignore
        return elementList.filter(o => Object.keys(o).filter((k) => k != null).some((k) => String(_.get(o, k)).toLowerCase().includes(searchString.toLowerCase())));
    }

    return [];
};

export default CustomFilter;