import {combineReducers} from "redux";
import appointmentReducer from "./appointmentsReducer";
import doctorsListReducer from "./doctorsListReducer";
import centersReducer from "./centersReducer";
import specialitiesListReducer from "./specialitiesListReducer";
import patientState from "./Patient";
import otherDocumentsReducer from "./otherDocumentsReducer";

export const rootReducer = combineReducers({
    appointment: appointmentReducer,
    doctorsList: doctorsListReducer,
    centersList: centersReducer,
    specialitiesList: specialitiesListReducer,
    patientState: patientState,
    otherDocumentsList: otherDocumentsReducer,
});

export type RootState = ReturnType<typeof rootReducer>;