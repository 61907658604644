import * as React from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { Password } from "../../components/FormComponents";
import { useHistory } from "react-router-dom";
import { useSnackbar } from 'notistack';
import FormField from "../../components/FormComponents/FormField";
import $api from "../../http";
import { ResponseAuth } from "../../types/Response";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import {PatientActionTypes} from "../../types/Store/patient";
import {Header} from "../../modules";

const SignInPage: React.FC = () => {
    const history = useHistory();
    const { enqueueSnackbar } = useSnackbar()
    const [validForm, setValidForm] = React.useState<object>(
        {
            login: false,
            password: false
        }
    );

    if (localStorage.getItem('token')) {
        history.push('/main/personal-cabinet');
    }

    const dispatch = useDispatch();

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        localStorage.removeItem("token");

        sessionStorage.setItem('isEsiaLogout', 'false');
        $api.post<ResponseAuth>(
            'auth/login',
            {
                login: data.get('login'),
                password: data.get('password')
            }
        )
            .then((responseData) => {
                if (responseData.status === 200) {
                    localStorage.setItem('token', responseData.data.token);
                    dispatch({type: PatientActionTypes.SET_CHILDREN_PATIENTS, payload: responseData.data.patients})
                    dispatch({ type: PatientActionTypes.SET_PATIENT, payload: responseData.data.patients[0]});
                    history.push("/main/personal-cabinet");
                }
            }).catch((error) => {
                enqueueSnackbar(error.response.data, { variant: "error" });
                console.error(error);
            });
    };

    const esiaAuth = async () => {
        sessionStorage.removeItem("token");

        $api.post(
            'auth/getEsiaAuthenticationUri',
            { code: "", refreshToken: "", redirectUri: "https://lk2.doctor-arbitailo.ru/esia" }
        )
            .then((responseData) => {
                if (responseData.status === 200) {
                    window.location.href = responseData.data.response;
                }
            }).catch((error) => {
                enqueueSnackbar(error.response.data, { variant: "error" });
                console.error(error);
            });
    }


    useEffect(() => {
        document.title = "Вход";
    }, [])

    return (
        <>
            <Header/>
            <Container component="main" maxWidth="xs">

                <CssBaseline />
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Typography component="h1" variant="h5">
                        {"Вход в личный кабинет"}
                    </Typography>
                    <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                        <Grid container justifyContent={"center"} alignItems={"center"} spacing={2}>
                            <Grid item xs={12} sm={12}>
                                <FormField
                                    label={"Логин/СНИЛС/Email адрес"}
                                    validForm={validForm}
                                    setValidForm={setValidForm}
                                    name={"login"}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <Password
                                    label={"Пароль"}
                                    validForm={validForm}
                                    setValidForm={setValidForm}
                                    name={"password"}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Button
                                    onClick={esiaAuth}
                                    fullWidth
                                    variant="contained"
                                    color={"secondary"}
                                    sx={{height: 50}}
                                >
                                    {"Войти через госуслуги (ЕСИА)"}
                                </Button>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Button
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    color={"secondary"}
                                    sx={{height: 50}}
                                >
                                    {"Войти"}
                                </Button>
                            </Grid>
                            <Grid item xs>
                                <Link href={"/forgot-password"} variant="body2">
                                    {"Забыли пароль?"}
                                </Link>
                            </Grid>
                            <Grid item>
                                <Link href={"/sign-up"} variant="body2">
                                    {"Новый пользователь"}
                                </Link>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Container>
        </>
    );
}

export default SignInPage;