import React from "react";
import {
    Backdrop,
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid,
    IconButton,
    Stack,
    Typography
} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import {PhoneNumber} from "../../FormComponents";
import {checkConfirmationCode, checkPhone} from "../../FormComponents/Validations/Validations";
import {checkFormBeforeSubmit} from "../../FormComponents/Controller";
import FormField from "../../FormComponents/FormField";
import {useTimer} from 'react-timer-hook';
import {useSnackbar} from "notistack";
import $api from "../../../http";
import CircularLoader from "../../CircularLoader";

interface DialogProps {
    open: boolean,
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    dialogTitle: string,
    handleClose?: (event: React.SyntheticEvent) => void,
    onAgreeClick?: (event: React.SyntheticEvent) => void,
    selectedDate: string,
    selectedMisId: string,
    selectedMisName: string,
    selectedDocId: string,
    selectedDocName: string,
    selectedTime: string
    unblockReservationTime?: (selectedDoctorId: string, selDate: string, reserved: boolean, time: string) => void;
}

const DialogUnauthorized = (props: DialogProps) => {
    const {
        open,
        setOpen,
        dialogTitle,
        handleClose,
        selectedDate,
        selectedMisId,
        selectedMisName,
        selectedDocName,
        selectedTime,
        selectedDocId,
        unblockReservationTime
    } = props;

    const {
        seconds,
        minutes,
        restart,
        isRunning,
        pause
    } = useTimer({
        expiryTimestamp: new Date(new Date().setSeconds(new Date().getSeconds() + 600)),
        autoStart: false
    });
    const {enqueueSnackbar} = useSnackbar();
    const [correctPhone, setCorrectPhone] = React.useState<object>({phoneNumber: false});
    const [correctCode, setCorrectCode] = React.useState<object>({confirmationCode: false});
    const [blockConfirmButton, setBlockConfirmButton] = React.useState<boolean>(false);

    function sleep(ms: number) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    const onCloseClick = () => {
        setOpen(false);
        pause();
        setCorrectPhone({phoneNumber: false});
        setCorrectCode({confirmationCode: false});
        setBlockConfirmButton(false);

        if (unblockReservationTime) {
            unblockReservationTime(selectedDocId, selectedDate, false, selectedTime);
        }
    }

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);

        if (!isRunning) {
            $api.post('event-notification/generate-confirmation-code',
                data.get('phoneNumber'),
                {
                    headers: {'Content-Type': 'application/json'}
                })
                .then(async (responseData) => {
                    if (responseData.status === 200) {
                        const time = new Date();
                        time.setSeconds(time.getSeconds() + 600);
                        restart(time);
                        enqueueSnackbar(responseData.data, {variant: "success"})
                    }
                }).catch((error) => {
                    enqueueSnackbar(error.response.data, {variant: "error"});
                    console.error(error);
                });
        } else {
            setBlockConfirmButton(true);
            $api.post('appointment/book-unauthorized',
                {
                    phone: data.get('phoneNumber'),
                    confirmationCode: data.get('confirmationCode'),
                    date: selectedDate.split('.').reverse().join('-'),
                    misId: selectedMisId,
                    docId: selectedDocId,
                    time: selectedTime,
                    infoMessage: `Вы успешно записались на прием по адресу ${selectedMisName} к ${selectedDocName} на ${selectedDate} в ${selectedTime}.`
                },
                {
                    headers: {'Content-Type': 'application/json'}
                }).then(async (responseData) => {
                if (responseData.status === 200) {
                    enqueueSnackbar(responseData.data, {variant: "success"});
                    await sleep(2000);
                    onCloseClick();
                    window.location.assign(window.location.origin + '/');
                }
            }).catch((error) => {
                setBlockConfirmButton(false);
                enqueueSnackbar(error.response.data, {variant: "error"});
                console.error(error);
            });
        }
    };
    if (blockConfirmButton) {
        return (
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={blockConfirmButton}
            >
                <CircularLoader/>
            </Backdrop>
        )
    } else {
        return (
            <Dialog
                BackdropProps={{style: {opacity: 0.4}}}
                fullWidth={true}
                maxWidth={'sm'}
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <Box component="form" onSubmit={handleSubmit} noValidate sx={{mt: 1}}>
                    <DialogTitle id="alert-dialog-title">
                        <Stack>
                            <Typography fontSize={14} fontWeight="bold" m={0}>
                                {dialogTitle}
                            </Typography>
                            <IconButton
                                aria-label="close"
                                onClick={onCloseClick}
                                sx={{
                                    position: 'absolute',
                                    right: 8,
                                    top: 8,
                                    color: (theme) => theme.palette.grey[500],
                                }}
                            >
                                <CloseIcon/>
                            </IconButton>
                        </Stack>
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText sx={{whiteSpace: "pre-line"}} id="alert-dialog-description">
                            {`Для подтверждения записи на прием по адресу ${selectedMisName} к ${selectedDocName} на ${selectedDate} в ${selectedTime} необходимо ввести код подтверждения`}
                        </DialogContentText>
                        <Grid container alignItems="center" spacing={2}>
                            <Grid item xs={12} sm={8}>
                                <PhoneNumber required={true} validForm={correctPhone} setValidForm={setCorrectPhone}
                                             additionalCheck={checkPhone} readOnly={isRunning}/>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <Button color={'secondary'} disabled={checkFormBeforeSubmit(correctPhone) || isRunning}
                                        type={'submit'}>Получить код</Button>
                            </Grid>
                            {isRunning ?
                                <>
                                    <Grid item xs={12} sm={6}>
                                        <FormField required={true} label={'Код подтверждения'} name={'confirmationCode'}
                                                   validForm={correctCode} setValidForm={setCorrectCode}
                                                   additionalCheck={checkConfirmationCode}/>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Typography>Валиден в
                                            течение {(Math.log(minutes) * Math.LOG10E + 1 | 0) === 2 ? "" : 0}{minutes}:{(Math.log(seconds) * Math.LOG10E + 1 | 0) === 2 ? "" : 0}{seconds}</Typography>
                                    </Grid>
                                </>
                                : <></>}
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button type={'submit'} color={'secondary'} autoFocus
                                disabled={checkFormBeforeSubmit(correctCode) || checkFormBeforeSubmit(correctPhone) || blockConfirmButton}>Подтвердить</Button>
                        <Button onClick={onCloseClick} color={'info'}>
                            Отмена
                        </Button>
                    </DialogActions>
                </Box>
            </Dialog>
        );
    }
}


export default DialogUnauthorized;