import React from 'react';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import ruLocale from 'date-fns/locale/ru';
import {SnackbarKey, SnackbarProvider} from 'notistack';
import {IconButton} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import AppRouter from "./components/AppRouter";
import {StyledEngineProvider} from '@mui/styled-engine';
import './styles/style.scss'
import ApplicationsProvider from "./providers/ApplicationsProvider/ApplicationsProvider";

const App: React.FC = () => {

    const noticeRef: React.Ref<any> = React.createRef();
    const onClickDismiss = (key: SnackbarKey) => () => {
        noticeRef.current.closeSnackbar(key);
    }

    return (

        <StyledEngineProvider injectFirst>
            <ApplicationsProvider>
            <LocalizationProvider dateAdapter={AdapterDateFns} locale={ruLocale}>
                <SnackbarProvider
                    autoHideDuration={6000}
                    maxSnack={3}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    ref={noticeRef}
                    action={(key: SnackbarKey) => (
                        <IconButton aria-label="upload picture" component="span"
                                    onClick={onClickDismiss(key)}>
                            <CloseIcon/>
                        </IconButton>
                    )}>
                    <AppRouter/>
                </SnackbarProvider>
            </LocalizationProvider>
            </ApplicationsProvider>
        </StyledEngineProvider>
    )
}

export default App;
