import {PatientAction, PatientActionTypes, PatientState} from "../../types/Store/patient";

const initState: PatientState = {
    patient: JSON.parse(localStorage.getItem('selectedPatient') || '{}'),
    childrenPatients: JSON.parse(localStorage.getItem('patients') || '[]')
}

const patientState = (state = initState, action: PatientAction): PatientState => {
    switch (action.type) {
        case PatientActionTypes.SET_PATIENT:
            localStorage.setItem('selectedPatient', JSON.stringify(action.payload))
            return {
                ...state,
                patient: action.payload
            }
        case PatientActionTypes.SET_CHILDREN_PATIENTS:
            localStorage.setItem('patients', JSON.stringify(action.payload))
            return {
                ...state,
                childrenPatients: action.payload
            }
        case PatientActionTypes.CLEAR_PATIENT_STATE:
            return {
                ...state,
                childrenPatients: []
            }
        default:
            return state
    }
}

export default patientState;