export type IsObjectInArray = <T>(array: Array<T>, desiredObject: T) => boolean;

/**
 * Функция, которая проверяет если есть искомый объект в массиве.
 * Функция возращает true если есть, false если нет.
 * @param array
 * @param desiredObject
 */
const isObjectInArray: IsObjectInArray = (array, desiredObject) => {
    return !!array.find((object) => JSON.stringify(object) === JSON.stringify(desiredObject));
}

export default isObjectInArray;