import React from "react";
import {useNavigateAppointment} from "./useNavigateAppointment";

export const useAppointmentLogic = () => {
    const [selectedTab, setSelectedTab] = React.useState(sessionStorage.getItem('selectedTab') || 'centers');
    const navigateAppointment = useNavigateAppointment();

    const handleClickCenters = () => {
        setSelectedTab('centers');

        navigateAppointment.navigateCenters();
    }

    const handleClickDirections = () => {
        setSelectedTab('directions');

        navigateAppointment.navigateDirections();
    }

    const handleClickSpecialists = () => {
        setSelectedTab('specialists');

        navigateAppointment.navigateSpecialists();
    }

    return {
        tab: {
            setSelectedTab,
            selectedTab
        },
        handleClickCenters,
        handleClickDirections,
        handleClickSpecialists,
    }
}