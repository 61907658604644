import React, {useEffect} from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import FormField from "../../components/FormComponents/FormField";
import Button from "@mui/material/Button";
import jwtDecode from "jwt-decode";
import User from "../../types/User";
import Container from "@mui/material/Container";
import {DateBirth, Password, PhoneNumber, Snils} from "../../components/FormComponents";
import {checkEmail} from "../../components/FormComponents/Validations";
import {checkPhone} from "../../components/FormComponents/Validations/Validations";
import {GlobalChangeStateObject} from "../../types/Global";
import {checkFormBeforeSubmit} from "../../components/FormComponents/Controller";
import {useSnackbar} from "notistack";
import {Backdrop, TextField} from "@mui/material";
import $api from "../../http";
import CircularLoader from "../../components/CircularLoader";

/**
 * Страница с настройками пользователя.
 * @constructor
 */
const SettingsPage: React.FC = () => {

    const token = localStorage.getItem("token");
    //@ts-ignore
    const user = jwtDecode<User>(token);
    const [userEntity, setUserEntity] = React.useState(user.userEntity);

    const [editFields, setEditFields] = React.useState<boolean>(false)
    const [validFormNew, setValidFormNew] = React.useState({
        phoneNumber: true,
        password: true,
        repeatPassword: false,
        email: true
    });

    const {enqueueSnackbar} = useSnackbar();

    const [newFieldValue, setNewFieldValue] = React.useState({
        email: userEntity.email,
        password: null,
        phoneNumber: userEntity.phoneNumber
    })

    const [requestLoading, setRequestLoading] = React.useState<boolean>(false)

    const changeNewFieldValue: GlobalChangeStateObject = (field, value) => {
        setNewFieldValue({
            ...newFieldValue,
            [field]: value
        })
    }

    const newSaveUser = () => {
        setEditFields(false)
        setRequestLoading(true)
        $api.put(`auth/updateUserData`,
            newFieldValue,
            {
                headers: {'Content-Type': 'application/json'}
            }).then(async function (response) {
            if (response.status === 200) {
                localStorage.setItem("token", response.data.token);
                setRequestLoading(false)
                enqueueSnackbar("Данные успешно изменены", {variant: "success"});
            }
        }).catch((error) => {
            setRequestLoading(false)
            enqueueSnackbar(error.response.data, {variant: "error"});
            console.error(error);
            rollback();
        });
    }

    const handleClick = () => {
        if (editFields) {
            newSaveUser()
        }
        setEditFields(!editFields)
    }

    const rollback = () => {
        //@ts-ignore
        const user = jwtDecode<User>(token);
        const oldUserEntity = user.userEntity;
        setUserEntity(oldUserEntity);
    }

    useEffect(() => {
        document.title = "Настройки";
    }, [])

    return (
        <Container component="main" maxWidth="sm">
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={requestLoading}
            >
                <CircularLoader/>
            </Backdrop>
            <Box component="form" className={'settings__page'} noValidate>
                <Grid container spacing={2} alignItems={"center"}>
                    <Grid item xs={12} sm={12}>
                        <Typography align={"center"} component="h1" variant="h5">
                            {"Настройки"}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <FormField
                            label={"ФИО"}
                            name={"lastName"}
                            defaultValue={`${userEntity.lastName} ${userEntity.firstName} ${userEntity.fatherName ?? ""}`}
                            disabled={true}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <DateBirth
                            defaultValue={userEntity.birthDay}
                            readOnly={true}
                            disabled={true}
                        />
                    </Grid>
                    {userEntity.snils ?
                        <Grid item xs={12} sm={12}>
                            <Snils
                                required={false}
                                readOnly={true}
                                defaultValue={userEntity.snils}
                            />
                        </Grid>
                    : <></>}
                    <Grid item xs={12} sm={12}>
                        <PhoneNumber
                            disabled={!editFields}
                            readOnly={!editFields}
                            edit={editFields}
                            changeState={changeNewFieldValue}
                            defaultValue={newFieldValue.phoneNumber ?? userEntity.phoneNumber}
                            validForm={validFormNew}
                            setValidForm={setValidFormNew}
                            additionalCheck={checkPhone}
                            rollbackEnabled={true}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <FormField
                            label={"Логин"}
                            name={"login"}
                            defaultValue={userEntity.login}
                            readOnly={true}
                            disabled={true}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        {editFields ?
                            <Password
                                edit={editFields}
                                readOnly={!editFields}
                                changeState={changeNewFieldValue}
                                label={"Пароль"}
                                validForm={validFormNew}
                                setValidForm={setValidFormNew}
                                name={"password"}
                            /> :
                            <TextField
                                fullWidth
                                id={"fakePassword"}
                                label={"Пароль"}
                                name={"fakePassword"}
                                value={"fakePassword"}
                                color="secondary"
                                type={'password'}
                                disabled={!editFields}
                            />}
                    </Grid>
                    {editFields ? (
                        <Grid item xs={12} sm={12}>
                            <Password
                                edit={editFields}
                                label={"Повторите пароль"}
                                validForm={validFormNew}
                                setValidForm={setValidFormNew}
                                name={"repeatPassword"}
                                repeatPassword
                            />
                        </Grid>
                    ) : (
                        <></>
                    )}

                    <Grid item xs={12} sm={12}>
                        <FormField
                            edit={editFields}
                            defaultValue={newFieldValue.email ?? userEntity.email}
                            validForm={validFormNew}
                            setValidForm={setValidFormNew}
                            changeState={changeNewFieldValue}
                            label={"Адрес электронной почты"}
                            additionalCheck={checkEmail}
                            name={"email"}
                            rollbackEnabled={true}
                            disabled={!editFields}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <Button
                            fullWidth
                            disabled={editFields ? checkFormBeforeSubmit(validFormNew) : false}
                            onClick={handleClick}
                            variant="contained"
                            color={"secondary"}
                        >
                            {editFields ? "Сохранить" : "Изменить"}
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        </Container>
    )
}

export default React.memo(SettingsPage);
