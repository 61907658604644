import {AppointmentAction, AppointmentActionTypes, AppointmentState} from "../../types/Store/appointment";

const initState: AppointmentState = {
    appointments: [],
    loading: false,
    refresh: false,
}

const appointmentReducer = (state = initState, action: AppointmentAction): AppointmentState => {
    switch (action.type) {
        case AppointmentActionTypes.FETCH_APPOINTMENTS:
            return {loading: true, appointments: [], refresh: state.refresh}
        case AppointmentActionTypes.FETCH_APPOINTMENTS_SUCCESS:
            return  {loading: false, appointments: action.payload, refresh: state.refresh}
        case AppointmentActionTypes.FETCH_APPOINTMENTS_ERROR:
            return {loading: false, appointments: state.appointments, refresh: state.refresh}
        case AppointmentActionTypes.CANCEL_APPOINTMENTS_SUCCESS: {
            return {loading: false, appointments: state.appointments, refresh: !state.refresh}
        }
        default:
            return state
    }
}

export default appointmentReducer;