import React from "react";
import TextField from "@mui/material/TextField";
import ErrorMessage from "./ErrorMessage";
import {FormComponentProps} from "./FormComponentProps";
import {IconButton, Stack} from "@mui/material";
import {Visibility, VisibilityOff} from "@mui/icons-material";
import {changeValidForm} from "./Controller";
import {checkEmptyField, checkAllowedEmptyField} from "./Validations";

export default function FormField(props: FormComponentProps) {
    const {
        label,
        required = false,
        setValidForm,
        validForm,
        showPassword = true,
        handleClickShowPassword,
        name,
        additionalCheck,
        defaultValue,
        readOnly,
        changeState,
        edit,
        editAllowedEmpty,
        endAdornment,
        rollbackEnabled,
        disabled
    } = props;

    const [value, setValue] = React.useState<string | number>(defaultValue ?? "");
    const [correct, setCorrect] = React.useState<boolean>(true);
    const [message, setMessage] = React.useState<string>("");

    const checkValue = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value: string = event.target.value;

        if (required || edit) {
            const {correctCurrent, message} = checkEmptyField(value, additionalCheck);

            changeValidForm({
                field: name,
                fieldValue: correctCurrent,
                validForm,
                setValidForm
            });

            setMessage(message);
            setCorrect(correctCurrent);
        }
        if (editAllowedEmpty) {
            const {correctCurrent, message} = checkAllowedEmptyField(value, additionalCheck);

            changeValidForm({
                field: name,
                fieldValue: correctCurrent,
                validForm,
                setValidForm
            });

            setMessage(message);
            setCorrect(correctCurrent);
        }
        if (changeState) {
            changeState(name, value)
        }
        setValue(value);
    }

    return (
        <TextField
            required={required}
            disabled={disabled}
            fullWidth
            id={name}
            label={label}
            name={name}
            value={rollbackEnabled ? defaultValue : value}
            onChange={checkValue}
            color="secondary"
            error={!correct}
            autoComplete={name}
            type={showPassword ? 'text' : 'password'}
            InputProps={{
                readOnly: readOnly,
                endAdornment: endAdornment ? endAdornment : (
                    <Stack direction={"row"} justifyContent={"center"} alignItems={"center"}>
                        {(name === "password" || name === "repeatPassword") && (
                            <IconButton
                                aria-label="password"
                                onClick={handleClickShowPassword}
                                edge="end"
                                tabIndex={-1}
                            >
                                {showPassword ? <VisibilityOff/> : <Visibility/>}
                            </IconButton>
                        )}
                        <ErrorMessage correct={correct} message={message}/>
                    </Stack>
                ),
            }}
        />
    )
}