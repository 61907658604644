import React from "react";
import {socketConnect, socketSend} from "../../SocketClient";
import {BOOKING_STATE_SUBSCRIPTION_PATH} from "../../../constants/path";
import {useDispatch} from "react-redux";
import {useTypedSelector} from "../../../hooks/useTypedSelector";
import {Doctor} from "../../../types/Doctor";
import {useSnackbar} from "notistack";
import {useHistory} from "react-router-dom";
import {messageCallbackType} from "@stomp/stompjs/src/types";
import {fetchDoctorsList, handleChangeBookingState} from "../../../store/action-creators/doctorsList/doctorsList";
import isObjectInArray from "../../../helpers/isObjectInArray";
import $api from "../../../http";
import {formatDate} from "../../../helpers";

const useListDoctorsLogic = () => {
    const dispatch = useDispatch();
    const doctorsListState = useTypedSelector(state => state.doctorsList);
    const selectedPatient = useTypedSelector(state => state.patientState.patient);

    const [selectedDate, setSelectedDate] = React.useState<string>(formatDate(new Date(), 'dd.mm.yy'));
    const [openAuthorized, setOpenAuthorized] = React.useState(false);
    const [openUnauthorized, setOpenUnauthorized] = React.useState(false);
    const [selectedDoctor, setSelectedDoctor] = React.useState<Doctor>();
    const [selectedTimeSlot, setSelectedTimeSlot] = React.useState<string>();
    const {enqueueSnackbar} = useSnackbar();
    const history = useHistory();

    const subscribeCallback: messageCallbackType = React.useCallback((message) => {
        const bookingStateData = JSON.parse(message.body);

        dispatch(handleChangeBookingState(bookingStateData));
    }, [dispatch]);


    const checkBookingDate = (doctorId: string, date: string, time: string) => {
        return isObjectInArray(doctorsListState.bookingDates, {
            doctorId: doctorId,
            date: date.split('.').reverse().join('-'),
            time: time,
            reserved: true,
            used: false
        });
    }

    React.useLayoutEffect(() => {
        socketConnect("/topic/booking-status", subscribeCallback);
    }, [subscribeCallback]);

    React.useEffect(() => {
        window.onbeforeunload = () => {
            let bookingData = {
                doctorId: selectedDoctor?.id,
                date: selectedDate.split('.').reverse().join('-'),
                time: selectedTimeSlot,
                reserved: false,
                used: false
            };

            socketSend(BOOKING_STATE_SUBSCRIPTION_PATH, bookingData);
        };
    });

    React.useEffect(() => {
        dispatch(fetchDoctorsList());
    }, [dispatch]);

    const handleChangeSelectedDate = (newSelectedDate: string) => {
        setSelectedDate(newSelectedDate);
    };

    const changeBookingState = (selectedDoctorId: string, selDate: string, reserved: boolean, time: string) => {
        let bookingData = {
            doctorId: selectedDoctorId,
            date: selDate.split('.').reverse().join('-'),
            time: time,
            reserved: reserved,
            used: false
        };

        socketSend(BOOKING_STATE_SUBSCRIPTION_PATH, bookingData);
    };

    const generateDialogBodyText = () => {
        return `Вы будете записаны на прием по адресу ${selectedDoctor?.misaddress} к ${selectedDoctor?.fullName} на ${selectedDate.split("|")[0]} в ${selectedTimeSlot}.
        Продолжить?`;
    };

    const agreed = () => {
        setOpenAuthorized(false);

        $api.post(
            'appointment/book',
            {
                docId: selectedDoctor?.id,
                misId: selectedDoctor?.misid,
                date: selectedDate.split('.').reverse().join('-'),
                time: selectedTimeSlot,
                patientId: selectedPatient.id
            }
        )
            .then((responseData) => {
                if (responseData.status === 200) {
                    history.push('/main/personal-cabinet');
                    enqueueSnackbar("Вы успешно записались на прием", {variant: "success"});

                    let bookingData = {
                        doctorId: selectedDoctor?.id,
                        date: selectedDate.split('.').reverse().join('-'),
                        time: selectedTimeSlot,
                        reserved: true,
                        used: true
                    };

                    socketSend(BOOKING_STATE_SUBSCRIPTION_PATH, bookingData);
                }
            }).catch((error) => {
            enqueueSnackbar(`Не удалось записаться на прием\nПричина: ${error.response.data}`, {
                variant: "error",
                style: {whiteSpace: "pre-line"}
            });

            let bookingData = {
                doctorId: selectedDoctor?.id,
                date: selectedDate.split('.').reverse().join('-'),
                time: selectedTimeSlot,
                reserved: false,
                used: false
            };

            socketSend(BOOKING_STATE_SUBSCRIPTION_PATH, bookingData);

            console.error(error);
        });
    };

    return ({
        checkBookingDate,
        agreed,
        generateDialogBodyText,
        changeBookingState,
        handleChangeSelectedDate,
        doctorsListState,
        timeSlot: {
            selectedTimeSlot,
            setSelectedTimeSlot
        },
        doctor: {
            selectedDoctor,
            setSelectedDoctor
        },
        date: {
            selectedDate,
            setSelectedDate
        },
        authorizedDialog: {
            openAuthorized,
            setOpenAuthorized
        },
        unauthorizedDialog: {
            openUnauthorized,
            setOpenUnauthorized
        }
    })
}

export default useListDoctorsLogic;