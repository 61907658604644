import React from 'react';
import {
    Header,
    Footer,
} from '../../components'
import {Redirect, Route, Switch} from "react-router-dom";
import {mainPrivateRoutes, mainPublicRoutes, RouteNames} from "../../router";

const MainPage: React.FC = () => {

    const auth = localStorage.getItem("token")

    return (
        <div className={'page'}>
            <Header/>
            <div className={'page-body'}>
                {
                    auth ? (
                        <Switch>
                            {mainPrivateRoutes.map((route) => (
                                <Route
                                    key={route.path}
                                    path={route.path}
                                    exact={route.exact}
                                    component={route.component}
                                />
                            ))}
                            <Redirect to={RouteNames.PERSONAL_CABINET}/>
                        </Switch>
                    ) : (
                        <Switch>
                            {mainPublicRoutes.map((route) => (
                                <Route
                                    key={route.path}
                                    path={route.path}
                                    exact={route.exact}
                                    component={route.component}
                                />
                            ))}
                            <Redirect to={RouteNames.SIGNIN}/>
                        </Switch>
                    )
                }
            </div>
            <Footer/>
        </div>
    )
}

export default MainPage;