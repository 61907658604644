import {Patient} from "../Patient";

export type PatientState = {
    patient: Patient,
    childrenPatients: Array<Patient>
}

export enum PatientActionTypes {
    SET_PATIENT = 'SET_PATIENT',
    SET_CHILDREN_PATIENTS = 'SET_CHILDREN_PATIENTS',
    CLEAR_PATIENT_STATE = 'CLEAR_PATIENT_STATE'
}

type SetPatientAction = {
    type: PatientActionTypes.SET_PATIENT,
    payload: Patient
}

type SetChildrenPatientAction = {
    type: PatientActionTypes.SET_CHILDREN_PATIENTS,
    payload: Array<Patient>
}

type ClearPatientState = {
    type: PatientActionTypes.CLEAR_PATIENT_STATE,
}

export type PatientAction = SetPatientAction | SetChildrenPatientAction | ClearPatientState;