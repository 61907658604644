import * as React from 'react';
import {Container, Stack} from "@mui/material";
import {useTypedSelector} from "../../hooks/useTypedSelector";
import './style.scss';
import {BurgerMenuMobile, Logo, MenuDesktop, Phone, SignInIcon} from "./components";
import ViewPatients from "./components/ViewPatients";

const Header = () => {

    const patientState = useTypedSelector(state => state.patientState)

    return (
        <div className={'header'}>
            <Container maxWidth={'xl'}>
                <div className={'header__inner'}>
                    <Logo/>
                    <Phone/>
                    <Stack direction={"row"} alignItems={"center"} spacing={1}>
                        <ViewPatients
                            patients={patientState.childrenPatients}
                            selectedPatient={patientState.patient}
                        />
                        <MenuDesktop/>
                        <SignInIcon/>

                        <BurgerMenuMobile/>
                    </Stack>
                </div>
            </Container>
        </div>
    )
}

export default Header;