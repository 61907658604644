import {Appointment} from "../Appointment";

export enum AppointmentActionTypes {
    FETCH_APPOINTMENTS = 'FETCH_APPOINTMENTS',
    FETCH_APPOINTMENTS_SUCCESS = 'FETCH_APPOINTMENTS_SUCCESS',
    FETCH_APPOINTMENTS_ERROR = 'FETCH_APPOINTMENTS_ERROR',
    CANCEL_APPOINTMENTS_SUCCESS = 'CANCEL_APPOINTMENTS_SUCCESS'
}

type FetchAppointmentsAction = {
    type: AppointmentActionTypes.FETCH_APPOINTMENTS,
}

type FetchAppointmentsSuccessAction = {
    type: AppointmentActionTypes.FETCH_APPOINTMENTS_SUCCESS,
    payload: Array<Appointment>
}

type FetchAppointmentsErrorAction = {
    type: AppointmentActionTypes.FETCH_APPOINTMENTS_ERROR,
    payload: string
}

type CancelAppointmentsSuccessAction = {
    type: AppointmentActionTypes.CANCEL_APPOINTMENTS_SUCCESS
}

export type AppointmentAction = FetchAppointmentsAction |
    FetchAppointmentsSuccessAction | FetchAppointmentsErrorAction |
    CancelAppointmentsSuccessAction

export type AppointmentState = {
    appointments: Array<Appointment>,
    loading: boolean,
    refresh: boolean
}