import {AppointmentAction, AppointmentActionTypes} from "../../../types/Store/appointment";
import {Dispatch} from "redux";
import $api from "../../../http";
import {EnqueueSnackbar} from "../../../types/Global/Global";
import axios from "axios";

const FETCH_ERROR_MESSAGE = 'Не удалось загрузить записи'
const CANCEL_ERROR_MESSAGE = 'Не удалось отменить запись'

export const fetchAppointments = (type: string, patientId: string) => {

    return async (dispatch: Dispatch<AppointmentAction>) => {
        try {
            dispatch({type: AppointmentActionTypes.FETCH_APPOINTMENTS});
            const response = await $api.get(`appointment/findAll?type=${type}&patientId=${patientId}`);

            if (response.status === 200) {
                dispatch({
                    type: AppointmentActionTypes.FETCH_APPOINTMENTS_SUCCESS,
                    payload: response.data
                });
            } else {
                dispatch({
                    type: AppointmentActionTypes.FETCH_APPOINTMENTS_ERROR,
                    payload: FETCH_ERROR_MESSAGE
                });
            }
        } catch (error) {
            console.error(error);

            dispatch({
                type: AppointmentActionTypes.FETCH_APPOINTMENTS_ERROR,
                payload: FETCH_ERROR_MESSAGE
            });
        }
    }
}

export const deleteAppointments = (appointmentId: string, enqueueSnackbar: EnqueueSnackbar) => {
    return async (dispatch: Dispatch<AppointmentAction>) => {
        try {
            const response = await $api.put(`appointment/cancel?id=${appointmentId}`);

            if (response.status === 200) {
                dispatch({
                    type: AppointmentActionTypes.CANCEL_APPOINTMENTS_SUCCESS,
                });
                enqueueSnackbar("Вы успешно отменили прием", {variant: "success"});
            } else {
                dispatch({
                    type: AppointmentActionTypes.FETCH_APPOINTMENTS_ERROR,
                    payload: CANCEL_ERROR_MESSAGE
                });
                enqueueSnackbar(`Не удалось отменить приём\nПричина: ${response.data}`, {variant: "error", style: {whiteSpace: "pre-line"}});
            }
        } catch (error) {
            if (axios.isAxiosError(error)) {
                enqueueSnackbar(error.response?.data, {variant: "error"});
            } else {
                console.error(error);
            }
        }
    }
}

