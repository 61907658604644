import React from "react";
import {InputAdornment, Tooltip} from "@mui/material";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

interface ErrorMessageProps {
    correct: boolean,
    message: string
}

export default function ErrorMessage(props: ErrorMessageProps) {
    const {correct, message} = props;

    return (
        <InputAdornment position="end">
            {!correct ? (
                <Tooltip title={message}>
                    <ErrorOutlineIcon color={"error"}/>
                </Tooltip>
            ) : (<></>)}
        </InputAdornment>
    )
}