import React from "react";
import {DaysOfRecipientProps} from "./types";
import {Button, Grid, Tooltip, Typography} from "@mui/material";
import {TabPanel} from "@mui/lab";
import {formatDate} from "../../../helpers";

const DaysOfRecipient: React.FC<DaysOfRecipientProps> = (props) => {
    return (
        <div>
            {props.doctor.availableDates.map((availableDate, index) => (
                <TabPanel key={`${index} availableDateTime`}
                          value={availableDate.dayOfRecipient + "|" + props.doctor.id}>
                    {availableDate.time.length ? (
                        <Grid className={'days-of-recipient-grid'} container spacing={1}
                              columns={{xs: 12, sm: 6, md: 3, mt: 1}}>
                            {availableDate.time.map((timeSlot) => (
                                <Grid sx={{width: 78, height: 45}} item
                                      key={props.doctor.id + "|" + availableDate.dayOfRecipient + "|" + timeSlot.time}>
                                    <Tooltip
                                        title={props.checkBookingDate(props.doctor.id, availableDate.dayOfRecipient, timeSlot.time) || timeSlot.reserved ?
                                            "Время забронировано" : "Время свободно"}>
                                    <span>
                                        <Button
                                            sx={{
                                                borderRadius: 20,
                                            }}
                                            fullWidth={true}
                                            color={"success"}
                                            variant={"contained"}
                                            disabled={props.checkBookingDate(props.doctor.id, availableDate.dayOfRecipient, timeSlot.time) || timeSlot.reserved}
                                            onClick={() => {
                                                props.changeBookingState(props.doctor.id, availableDate.dayOfRecipient, true, timeSlot.time);
                                                props.setSelectedDoctor(props.doctor);
                                                props.setSelectedTime(timeSlot.time);
                                                // приходится делать такую конверсию, т.к. TS думает что availableDate.dayOfRecipient имеет тип Date
                                                // а браузер думает что это тип string
                                                console.log(availableDate)
                                                // const t = Date(availableDate.dayOfRecipient)
                                                props.handleChangeSelectedDate(formatDate(new Date(availableDate.dayOfRecipient), 'dd.mm.yy'));

                                                localStorage.getItem("token") ? props.setOpenAuthorized(true) : props.setOpenUnauthorized(true);
                                            }}
                                        >
                                        {timeSlot.time}
                                        </Button>
                                    </span>
                                    </Tooltip>
                                </Grid>
                            ))}
                        </Grid>
                    ) : (
                        <Typography fontWeight={400} color="#949494">
                            Нет доступного времени для записи
                        </Typography>
                    )}
                </TabPanel>
            ))}
        </div>
    )
}

export default DaysOfRecipient;