import React, {useEffect} from "react";
import {Button, Container, Stack} from "@mui/material";
import Search from "../../components/Search";
import {useHistory} from "react-router-dom";

import styles from "./styles"

const PrivacyPolicy: React.FC = () => {
    const history = useHistory();


    useEffect(() => {
        document.title = "Политика конфиденциальности";
    }, [])

    return (
        <div className={'content-root'}>
            <Container maxWidth="xl">
                <Button className={'tab'} onClick={() => history.goBack()}>
                    Назад
                </Button>
                <Stack alignItems={"center"} justifyItems={"center"}>
                    <Search/>
                </Stack>
            </Container>
            <div className={'content-card'}>
                <div className={'content-box'}>
                    <div className={'content-scrollable-box'}>
                        <Stack alignItems={"center"} justifyItems={"center"}>
                            <div className="wrapper" style={styles.Wrapper}>
                                <h1 className="page__title" style={styles.Header}>Политика конфиденциальности</h1>
                                <div className="inner-content p-contact__time" style={styles.InnerContent}>
                                    <p style={styles.Paragraph}>Настоящим свободно,
                                        своей волей и в своем интересе даю согласие ООО "Доктор
                                        Арбитайло",
                                        находящемуся по адресу г. Тюмень ул. Республики, д. 40, корп. 1 (далее
                                        – Оператор) на автоматизированную и неавтоматизированную
                                        обработку
                                        своих персональных данных в соответствии со следующим перечнем:
                                    </p>
                                    <ul style={styles.List}>
                                        <li style={styles.ListItem}>
                                        <span style={styles.Span}>
                                            имя, телефон, адрес электронной почты и иные индивидуальные
                                        средства коммуникации, указанные при заполнении на сайте
                                        http://doctor-arbitailo.ru (далее – Сайт) всех форм, а
                                        именно:
                                        </span>
                                        </li>
                                        <li style={styles.ListItem}>
                                        <span style={styles.Span}>
                                            использовании сервисов обратной связи с Администрацией
                                        сайта;
                                        </span>
                                        </li>
                                        <li style={styles.ListItem}>
                                        <span style={styles.Span}>
                                            источник захода на сайт и информация поискового или
                                        рекламного запроса;
                                        </span>
                                        </li>
                                        <li style={styles.ListItem}>
                                        <span style={styles.Span}>
                                            данные о пользовательском устройстве (среди которых
                                        разрешение, версия и другие атрибуты, характеризующие
                                        пользовательское устройство);
                                        </span>
                                        </li>
                                        <li style={styles.ListItem}>
                                        <span style={styles.Span}>пользовательские клики, просмотры страниц, заполнения полей,
                                        показы и просмотры баннеров и видео;
                                        </span>
                                        </li>
                                        <li style={styles.ListItem}><span style={styles.Span}>данные, характеризующие аудиторные сегменты;</span>
                                        </li>
                                        <li style={styles.ListItem}><span
                                            style={styles.Span}>параметры сессии;</span></li>
                                        <li style={styles.ListItem}><span style={styles.Span}>данные о времени посещения;</span>
                                        </li>
                                        <li style={styles.ListItem}><span style={styles.Span}>идентификатор пользователя, хранимый в cookie,</span>
                                        </li>
                                    </ul>
                                    <p style={styles.Paragraph}>
                                        для целей оказания информационных услуг, осуществления обратной
                                        связи с пользователем сайта, оптимизации сайта под потребности
                                        пользователя.
                                    </p>
                                    <p style={styles.Paragraph}>
                                        Также даю свое согласие на осуществление
                                        трансграничной передачи персональных данных для достижения
                                        заявленных целей обработки персональных данных.</p>
                                    <p style={styles.Paragraph}>
                                        В целях
                                        обеспечения реализации требований законодательства в области
                                        обработки персональных данных Оператор может:
                                    </p>
                                    <ul style={styles.List}>
                                        <li style={styles.ListItem}>
                                        <span style={styles.Span}>
                                            осуществлять обработку персональных данных путем сбора,
                                        хранения, систематизации, накопления, изменения, уточнения,
                                        использования, распространения, обезличивания, блокирования,
                                        уничтожения персональных данных;
                                        </span>
                                        </li>
                                        <li style={styles.ListItem}>
                                        <span style={styles.Span}>использовать автоматизированный способ обработки
                                        персональных данных с передачей по сети Интернет;
                                        </span>
                                        </li>
                                    </ul>
                                    <p style={styles.Paragraph}>
                                        Настоящее согласие вступает в силу с момента подтверждения и
                                        действует в течение 5 лет, если иное не установлено действующим
                                        законодательством РФ.
                                    </p>
                                    <p style={styles.Paragraph}>Отзыв согласия на обработку
                                        персональных данных осуществляется путем направления заявления в
                                        письменной форме на юридический адрес Оператора.
                                    </p>
                                    <p style={styles.Paragraph}>
                                        Я подтверждаю, что мне известно о праве отозвать свое согласие
                                        посредством составления соответствующего письменного документа,
                                        который может быть направлен мной в адрес Оператора. В случае
                                        моего отзыва согласия на обработку персональных данных Оператор
                                        вправе продолжить обработку персональных данных без моего
                                        согласия при наличии оснований, указанных в пунктах 2-11 части 1
                                        статьи 6, части 2 статьи 10 и части 2 статьи 11 Федерального
                                        закона от 27.07.2006г. №152-ФЗ «О персональных данных».
                                    </p>
                                </div>
                            </div>
                        </Stack>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PrivacyPolicy;