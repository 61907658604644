import React from "react";
import {CircularProgress} from "@mui/material";

const CircularLoader: React.FC = () => {

    return (
        <CircularProgress style={{
            position: "fixed",
            left: "50%",
            top: "50%",
            zIndex: "1000"
        }} color="secondary"/>
    )
}

export default CircularLoader;