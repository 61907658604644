import {useTypedSelector} from "../../../hooks/useTypedSelector";
import {useDispatch} from "react-redux";
import React from "react";
import fetchOtherDocuments from "../../../store/action-creators/otherDocuments/fetchOtherDocuments";
import CircularLoader from "../../CircularLoader";
import EmptyDataCustom from "../../Customs/EmptyDataCustom";
import {Button, Grid, Typography} from "@mui/material";
import {formatDate} from "../../../helpers";
import $api from "../../../http";
import {useSnackbar} from "notistack";

export default function OtherDocuments(): JSX.Element {
    const dispatch = useDispatch();
    const {enqueueSnackbar} = useSnackbar();

    const patientState = useTypedSelector(state => state.patientState);
    const otherDocuments = useTypedSelector(state => state.otherDocumentsList);

    React.useEffect(() => {
        dispatch(fetchOtherDocuments(patientState.patient.id));
    }, [patientState.patient.id, dispatch]);

    const handleDownload = (id: number) => {
        $api.get(`file/download/${patientState.patient.id}/${id}`,
            {
                responseType: "blob"
            }).then(async function (response) {
            if (response.status === 200) {
                const url = window.URL.createObjectURL(new Blob([response.data], {type: 'application/pdf'}));
                window.open(url);

                window.URL.revokeObjectURL(url);
            }
        }).catch((error) => {
            enqueueSnackbar(error.response.data, {variant: "error"});
            console.error(error);
        });
    }

    if (otherDocuments.loading) {
        return (
            <CircularLoader/>
        )
    } else if (!otherDocuments.docs.length) {
        return (
            <EmptyDataCustom/>
        )
    } else {
        return (
            <Grid spacing={2} container>
                {otherDocuments.docs.map((doc) => (
                    <Grid item xs={12} sm={6} md={4} key={doc.fileName + doc.remoteCreationTime}>
                        <div className={'record__doc box__shadow'}>
                            <div className={'record__doc__inner'}>
                                <Typography fontSize={20} component="div">
                                    {doc.fileName}
                                </Typography>
                                <Typography color={"text.secondary"} fontSize={14}>
                                    {formatDate(new Date(doc.remoteCreationTime), 'dd.mm.yy H:M')}
                                </Typography>
                            </div>
                            <Button color={'secondary'} variant={"contained"} onClick={() => handleDownload(doc.id)}>Скачать</Button>
                        </div>
                    </Grid>
                ))}
            </Grid>
        )
    }
}