import React from "react";
import {Box, Container, Stack} from "@mui/material";
import './style.scss'
import {PhoneLink, PrivacyPolicyLink, SocialLink, TermsOfUseLink} from "./components";

const Footer = () => {

    return (
        <footer className={'footer'}>
            <Container maxWidth={'xl'}>
                <Box className={'footer__inner'}>
                    <Box className={'footer__policy'}
                    >
                        <PrivacyPolicyLink/>
                        <TermsOfUseLink/>
                    </Box>
                    <Stack
                        direction={"row"}
                        spacing={2}
                        alignItems={"center"}
                        justifyItems={"center"}
                    >
                        <PhoneLink/>
                        <SocialLink/>
                    </Stack>
                </Box>
            </Container>
        </footer>
    )
}

export default Footer;