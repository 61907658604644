import React from "react";
import {Stack, Typography} from "@mui/material";
import {DateElementProps} from "./types";
import {formatDate} from "../../../helpers";

const getWeekDayName = (date: string) => {
    switch (new Date(date).getDay()) {
        case 0:
            return 'ВС';
        case 1:
            return 'ПН';
        case 2:
            return 'ВТ';
        case 3:
            return 'СР';
        case 4:
            return 'ЧТ';
        case 5:
            return 'ПТ';
        case 6:
            return 'СБ';
        default:
            return '';
    }
};

const DateElement: React.FC<DateElementProps> = ({availableDate}) => {
    return (
        <Stack alignItems="center">
            <Typography fontSize={12} fontWeight="bold" m={0}>
                {formatDate(new Date(availableDate.dayOfRecipient.toString()), 'dd.mm.yy')}
            </Typography>
            <Typography fontSize={14} sx={{textTransform: "lowercase"}}>
                {getWeekDayName(availableDate.dayOfRecipient.toString())}
            </Typography>
        </Stack>
    )
}

export default DateElement;