import $api from "../http";
import {useHistory} from "react-router-dom";
import {useSnackbar} from "notistack";

const useLogout = () => {
    const history = useHistory();

    const {enqueueSnackbar} = useSnackbar();

    return () => {
        const isEsiaLogout = (sessionStorage.getItem('isEsiaLogout') === 'true');
        $api.post(`auth-help/logOut`, {
            isEsiaLogout: isEsiaLogout,
            esiaRedirectUri: "https://lk2.doctor-arbitailo.ru/sign-in"
        })
            .then((responseData) => {
                if (responseData.status === 200) {
                    localStorage.clear();
                    sessionStorage.clear();

                    if (!isEsiaLogout) {
                        history.push('/sign-in');
                    } else {
                        window.location.href = responseData.data.response;
                    }
                }
            }).catch((error) => {
            enqueueSnackbar(error.response.data, {variant: "error"});
            console.error(error);
        });
    }
}

export default useLogout;