import React from "react";
import {Box, Stack} from "@mui/material";
import insertInvitationFilled from "../../../../images/InsertInvitationFilled.png";
import accessTimeFilledFilled from "../../../../images/AccessTimeFilledFilled.png";
import {DateFC} from "../../../../types/components";

const Date: React.FC<DateFC> = (
    {
        date,
        time
    }
) => {

    return (
        <Stack direction={"row"}
               spacing={3}
               sx={{mt: 1}}
        >
            <Box display={"flex"}>
                <img alt={"insertInvitationFilled"} src={insertInvitationFilled} style={{
                    height: 24,
                    width: 24
                }}/>
                <Box>
                    {date}
                </Box>
            </Box>
            <Box display={"flex"}>
                <img alt={"accessTimeFilledFilled"} src={accessTimeFilledFilled} style={{
                    height: 24,
                    width: 24
                }}/>
                <Box>
                    {time}
                </Box>
            </Box>
        </Stack>
    )
}

export default Date