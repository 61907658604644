import React from "react";
import {AvailableDatesProps} from "./types";
import {Tab, Typography} from "@mui/material";
import DateElement from "./DateElement";
import {TabContext, TabList} from "@mui/lab";
import DaysOfRecipient from "./DaysOfRecipient";
import {tabsClasses} from "@mui/material/Tabs";
import getTabValue from "../../../helpers/getTabValue";

const AvailableDates: React.FC<AvailableDatesProps> = (props) => {
    const {doctor} = props

    const [selectedDateLocal, setSelectedDateLocal] = React.useState(getTabValue(doctor))

    const changeValue = (event: React.SyntheticEvent, newSelectedDate: string) => {
        setSelectedDateLocal(newSelectedDate)
        props.handleChangeSelectedDate(newSelectedDate)
    }

    if (doctor.availableDates.length !== 0) {
        return (
            <TabContext value={selectedDateLocal}>
                    <TabList
                        variant="scrollable"
                        scrollButtons={"auto"}
                        onChange={changeValue}
                        className={'card-doctor-tabs'}
                        sx={{
                            color: 'secondary.main',
                            alignItems: "center",
                            [`& .${tabsClasses.scrollButtons}`]: {
                                border: 2,
                                borderColor: "secondary.main",
                                borderRadius: "50%",
                                padding: '10px',
                                width: 20,
                                height: 20,
                                '&.Mui-disabled': {opacity: 0.3},
                            },
                            '& .MuiTabs-indicator': {
                                backgroundColor: "secondary.main"
                            },
                        }}
                    >
                        {doctor.availableDates.map((availableDate, index) => (
                            <Tab
                                key={`${index} availableDate`}
                                label={<DateElement availableDate={availableDate}/>}
                                value={availableDate.dayOfRecipient + "|" + doctor.id}
                                onClick={() => {
                                    props.setSelectedDoctor(doctor);
                                }}
                                sx={{
                                    minWidth: 30,
                                    maxWidth: 92,
                                    '&:hover': {
                                        color: '#db5f5a',
                                        opacity: 1,
                                    },
                                    '&.Mui-selected': {
                                        color: 'secondary.main',
                                    },
                                }}
                            />
                        ))}
                    </TabList>
                    <DaysOfRecipient {...props}/>
            </TabContext>
        )
    } else {
        return (
            <Typography fontWeight={400} color="#949494">
                Нет доступных дат для записи
            </Typography>
        )
    }
}

export default AvailableDates;