import React, {useLayoutEffect, useState} from "react";
import $api from "../../http";
import {useParams} from "react-router-dom";
import {CovidResultInfo} from "../../types/Covid/CovidInfo";
import {Header} from "../../modules";
import {Box, Container} from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import './style.scss';
import CircularLoader from "../../components/CircularLoader";

type CovidInfoParams = {
    serviceId: string;
    patientId: string;
}

const CovidInfoPage: React.FC = () => {

    let {serviceId, patientId} = useParams<CovidInfoParams>();
    let [covidInfoResult, setCovidInfoResult] = useState<CovidResultInfo>();
    let [isLoading, setLoading] = useState(true);

    useLayoutEffect(() => {
        const getCovidResultInfo = async () => {
            setLoading(true);
            await $api.get(`covid/${serviceId}/${patientId}`)
                .then((responseData) => {
                    if (responseData.status === 200) {
                        setCovidInfoResult(responseData.data);
                        setLoading(false);
                    }
                }).catch((error) => {
                    setLoading(false);
                    console.error(error);
                });
        }

        getCovidResultInfo().then();
    }, [serviceId, patientId]);

    return (
        <>
            <Header/>
            {
                isLoading ? <CircularLoader /> :
                covidInfoResult ? (
                    <div className="container-fluid body" id="layout" style={{height: "100%"}}>
                        <div className="content bottom-border">
                            <div className="title">Проверка справки COVID-19</div>
                            <div className="results">
                                <div className="verification">Справка действительна</div>
                                <div className="field">
                                    <div className="field-label">Дата, время забора</div>
                                    <div className="field-text">{ covidInfoResult.dateSwab } { covidInfoResult.timeSwab }</div>
                                </div>
                                <div className="field">
                                    <div className="field-label">Пациент</div>
                                    <div className="field-text">{ covidInfoResult.patFIORu }</div>
                                </div>
                                <div className="field">
                                    <div className="field-label">Год рождения</div>
                                    <div className="field-text">{ covidInfoResult.patDateBirth }</div>
                                </div>
                                <div className="field">
                                    <div className="field-label">Дата готовности результата</div>
                                    <div className="field-text">{ covidInfoResult.dateResult }</div>
                                </div>
                                <div className="covid-info">Коронавирус COVID-19</div>
                                <div className="covid-status"></div>
                                <div className={`covid-status ${covidInfoResult.isPositive ? 'positive-result' : 'negative-result'}`}>{ covidInfoResult.resultRu }</div>
                            </div>
                        </div>

                        <div className="content">
                            <div className="title">Verification of a COVID-19</div>
                            <div className="results">
                                <div className="verification">The document is valid</div>
                                <div className="field">
                                    <div className="field-label">Date and time of sampling</div>
                                    <div className="field-text">{ covidInfoResult.dateSwab } { covidInfoResult.timeSwab }</div>
                                </div>
                                <div className="field">
                                    <div className="field-label">Patient</div>
                                    <div className="field-text">{ covidInfoResult.patFIOEn }</div>
                                </div>
                                <div className="field">
                                    <div className="field-label">Date of birth</div>
                                    <div className="field-text">{ covidInfoResult.patDateBirth }</div>
                                </div>
                                <div className="field">
                                    <div className="field-label">Result date</div>
                                    <div className="field-text">{ covidInfoResult.dateResult }</div>
                                </div>
                                <div className="covid-info">Coronavirus COVID-19</div>
                                <div className="covid-status"></div>
                                <div className={`covid-status ${covidInfoResult.isPositive ? 'positive-result' : 'negative-result'}`}>{ covidInfoResult.resultEn }</div>
                            </div>
                        </div>
                    </div>
                ) : (
                    <Container>
                        <CssBaseline/>
                        <Box
                            sx={{
                                marginTop: 8,
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'start',
                            }}>
                            <Typography sx={{fontWeight: 'bold'}} component="h1" variant="h4">
                                {"Произошла ошибка доступа, попробуйте позже!"}
                            </Typography>
                        </Box>
                    </Container>
                )
            }

        </>
    )
}

export default CovidInfoPage;