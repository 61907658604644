import React from "react";
import {Stack, Typography} from "@mui/material";
import smallAvatar from "../../../../images/small-avatar.png";
import locationOnFilled from "../../../../images/LocationOnFilled.png";
import medicalService from "../../../../images/MedicalServices.png";
import {Buttons} from "./HelpsComponents";
import DialogCustom from "../../../Customs/DialogCustom";
import {useSnackbar} from "notistack";
import {useDispatch} from "react-redux";
import {deleteAppointments} from "../../../../store/action-creators/appointment/appointment";
import {UserFC} from "../../../../types/components";

const User: React.FC<UserFC> = (
    {
        appointment
    }
) => {

    const [open, setOpen] = React.useState<boolean>(false);

    const {enqueueSnackbar} = useSnackbar();

    const dispatch = useDispatch()

    const generateDialogBodyText = () => {
        return `Вы хотите отменить запись на прием по адресу ${appointment.misAddress} к ${appointment.docFio} на ${appointment.date} в ${appointment.time}?`;
    };

    const agreed = () => {
        dispatch(deleteAppointments(appointment.id, enqueueSnackbar))
    };

    return (
        <Stack
            direction={"column"}
            justifyItems={"flex-start"}
            sx={{
                minWidth: "40%",
            }}
        >
            <Stack direction={"row"}
                   alignItems={"center"}
                   justifyContent="space-between"
                   spacing={1}
            >
                <Stack direction={"row"} alignItems={"center"} spacing={1} width={{md: 460, lg: 780}}>
                    {appointment.eventType === "Исследование"
                        ?
                        <><img src={medicalService} alt={"medicalService"} style={{
                            height: 18,
                            width: 18
                        }}/><Typography fontSize={20} fontWeight={500} component="div">
                            {appointment.serviceName}
                        </Typography></>
                        :
                        <><img src={smallAvatar} alt={"smallAvatar"} style={{
                            height: 16,
                            width: 16
                        }}/><Typography fontSize={20} fontWeight={500} component="div">
                            {appointment.serviceName}
                        </Typography></>
                    }
                </Stack>
                {appointment.eventType
                    ?
                    <Typography sx={{
                        backgroundColor: "secondary.main",
                        textTransform: "unset",
                        borderRadius: 16,
                        color: "primary.main",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        textAlign: "center",
                        pl: 0.8,
                        pr: 0.8,
                        pt: 0.4,
                        pb: 0.4,
                        fontSize: 14,
                    }}>
                        {appointment.eventType}
                    </Typography>
                    : <></>
                }
            </Stack>
            {appointment.eventType === "Исследование"
                ?
                <></>
                :
                <><Typography
                    ml={3}
                    fontSize={14}
                    fontWeight={400}
                    color={"text.secondary"}
                >
                    {appointment.docFio}, {appointment.docSpecialtyName}
                </Typography></>
            }
            <Stack direction={"row"} alignItems={"center"}>
                {appointment.eventType === "Исследование"
                    ?
                    <></>
                    :
                    <><img src={locationOnFilled} alt={"locationOnFilled"} style={{
                        height: 19,
                        width: 19
                    }}/>
                        <Typography
                            ml={0.625}
                            fontSize={14}
                            fontWeight={400}
                            color={"text.secondary"}
                        >
                            {appointment.misAddress}
                        </Typography></>
                }
            </Stack>
            <Buttons
                paid={!appointment.needPayment}
                isVisited={appointment.visited}
                eventType={appointment.eventType}
                setOpen={setOpen}
                fileDownloadExists={appointment.appointmentFileExists}
                idService={appointment.idService}
                files={appointment.files}
                quantityFiles={appointment.quantityFiles}
            />
            <DialogCustom
                open={open}
                setOpen={setOpen}
                dialogTitle="Отмена записи на прием"
                dialogText={generateDialogBodyText()}
                onAgreeClick={agreed}
            />
        </Stack>
    )
}

export default User