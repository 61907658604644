import React from "react";
import {Avatar, Collapse, List, ListItemAvatar, ListItemButton, ListItemText} from "@mui/material";
import {PatientState} from "../../../../../types/Store/patient";
import {useChangePatient} from "../../../../../hooks/Patient";

interface ListUserProps {
    patientState: PatientState,
    open: boolean
}

const ListUsers: React.FC<ListUserProps> = (
    {
        patientState,
        open
    }
) => {
    const handleChangePatientAvatar = useChangePatient()

    return (
        <Collapse in={open} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
                {patientState.childrenPatients.map((patient) => (
                    patientState.patient.id !== patient.id && (
                        <ListItemButton
                            key={patient.id}
                            sx={{pl: 4}}
                            onClick={() => handleChangePatientAvatar(patient)}
                        >
                            <ListItemAvatar>
                                <Avatar>
                                    {patient.lastName[0] + patient.firstName[0]}
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                                primary={`${patient.lastName} ${patient.firstName} ${patient.fatherName}`}
                            />
                        </ListItemButton>
                    )
                ))}
            </List>
        </Collapse>
    )
}

export default ListUsers;