import React from "react";
import {ListItemIcon, MenuItem} from "@mui/material";
import AddBoxIcon from '@mui/icons-material/AddBox';
import {MenuItemProps} from "./types";
import {useHistory} from "react-router-dom";
import {RouteNames} from "../../router";

const AppointmentItem: React.FC<MenuItemProps> = (
    {
        handleCloseParent
    }
) => {
    const history = useHistory()

    const handleClick = () => {
        history.push(RouteNames.APPOINTMENT)
        handleCloseParent()
    }

    return (
        <MenuItem onClick={handleClick}>
            <ListItemIcon>
                <AddBoxIcon/>
            </ListItemIcon>
            Запись на приём
        </MenuItem>
    )
}

export default AppointmentItem;