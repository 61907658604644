import React from "react";
import logo from "../../../images/logo.png";
import {Link} from "@mui/material";

const Logo: React.FC = () => {
    return (
        <Link
            className={'logo'}
            underline="none"
            color="inherit" href={localStorage.getItem("token") ? "/main/personal-cabinet" : "/sign-in"}
        >
            <img
                className={'logo'}
                src={logo}
                alt={"logo"}
            />
        </Link>
    )
}

export default Logo;