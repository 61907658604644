import React from "react";
import {Stack} from "@mui/material";
import {useSnackbar} from 'notistack';
import $api from "../../../../http";
import {FeedbackFC} from "../../../../types/components";
import {RatingAppointment} from "./HelpsComponents";

const Feedback: React.FC<FeedbackFC> = (
    {
        appointment,
        changeSingleAppointment,
        precision
    }
) => {
    const {enqueueSnackbar} = useSnackbar();

    const setNewRating = (newValue: number) => {
        appointment.ratingService = newValue;

        $api.post(
            'appointment/rateAppointment',
            appointment
        ).then((responseData) => {
            if (responseData.status === 200) {
                changeSingleAppointment(appointment);
                enqueueSnackbar("Оценка проставлена", {variant: "success"});
            }
        }).catch((error) => {
            enqueueSnackbar(error.response.data, {variant: "error"});
            console.error(error);
        });
    }

    const changeRating = (event: React.SyntheticEvent, newValue: number | null) => {
        if (newValue) {
            setNewRating(newValue);
        }
    }

    return (
        <div className={'feedback'}>
            <Stack
                direction={"row"}
                justifyContent={"flex-end"}
                spacing={2}
            >
            </Stack>
            <RatingAppointment
                changeRating={changeRating}
                appointment={appointment}
                precision={precision}
            />
        </div>
    )
}

export default Feedback;