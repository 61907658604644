import React from "react";
import {Button, Stack, Typography} from "@mui/material";
import {useSnackbar} from "notistack";
import $api from "../../../../../http";
import {File} from "../../../../../types/Appointment";
import ButtonMenu from "./ButtonMenu";

interface ButtonsProps {
    paid: boolean,
    isVisited: boolean,
    eventType: string,
    setOpen: React.Dispatch<React.SetStateAction<boolean>>,
    fileDownloadExists: boolean,
    idService: string,
    files: Array<File>,
    quantityFiles: number,
}

const Buttons = (props: ButtonsProps) => {
    const {paid, isVisited, eventType, setOpen, files, quantityFiles} = props;
    const {enqueueSnackbar} = useSnackbar();

    function openFileInNewTab() {
        $api.get(`appointment/book-file/${files[0].id}`,
            {
                responseType: "blob"
            }).then(async function (response) {
            if (response.status === 200) {
                const url = window.URL.createObjectURL(new Blob([response.data], {type: response.data.type}));
                window.open(url);

                window.URL.revokeObjectURL(url);
            }
        }).catch((error) => {
            enqueueSnackbar(error.response.data, {variant: "error"});
            console.error(error);
        });
    }

    return (
        <Stack direction={"row"} mt={2} spacing={2} alignItems={"center"}>
            {
                (!paid) ? (
                    <Typography color={'#D23731'} fontWeight={"bold"} fontSize={"14px"} mr={4}>
                        Требуется оплата
                    </Typography>
                ) : (
                    <Typography color={'#1BA683'} fontWeight={"bold"} fontSize={"14px"} mr={10.5}>
                        Оплачено
                    </Typography>
                )
            }

            { (eventType !== "Исследование") ?
                (
                    (isVisited) ? (
                        (quantityFiles === 1) ? (
                            <Button variant="contained"
                                    onClick={openFileInNewTab}
                                    disabled={!quantityFiles}
                                    sx={{
                                        textTransform: "unset",
                                        p: 0.5,
                                        backgroundColor: '#6666E2',
                                        color: 'primary.main',
                                        borderRadius: 16,
                                    }}>
                                Назначение
                            </Button>
                        ) : (
                            <ButtonMenu options={files} quantityFiles={quantityFiles}/>
                        )
                    ) : (
                        <Button variant="contained"
                                color={"secondary"}
                                onClick={() => (setOpen(true))}
                                sx={{
                                    textTransform: "unset",
                                    p: 0.5,
                                    borderRadius: 16,
                                }}>
                            Отменить
                        </Button>
                    )
                ) :
                (
                    (quantityFiles <= 1) ? (
                        <Button variant="contained"
                                onClick={openFileInNewTab}
                                disabled={!quantityFiles}
                                sx={{
                                    textTransform: "unset",
                                    p: 0.5,
                                    backgroundColor: '#6666E2',
                                    color: 'primary.main',
                                    borderRadius: 16,
                                }}>
                            Результат
                        </Button>
                    ) : (
                        <ButtonMenu options={files} quantityFiles={quantityFiles}/>
                    )
                )
            }
        </Stack>
    )
}

export default Buttons;