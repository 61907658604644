import React from "react";
import {Link} from "@mui/material";

const PrivacyPolicyLink: React.FC = () => {
    return (
        <Link
            className={'footer__link'}
            href={"/main/privacy-policy"}
        >
            Политика конфиденциальности
        </Link>
    )
}

export default PrivacyPolicyLink;