import React, {useEffect} from "react";
import {Button, Container, Stack} from "@mui/material";
import Search from "../../components/Search";
import {useHistory} from "react-router-dom";

import styles from "./styles"

const TermsOfUse: React.FC = () => {
    const history = useHistory();


    useEffect(() => {
        document.title = "Пользовательское соглашение";
    }, [])

    return (
        <div className={'content-root'}>
            <Container maxWidth="xl">
                <Button className={'tab'} onClick={() => history.goBack()}>
                    Назад
                </Button>
                <Stack alignItems={"center"} justifyItems={"center"}>
                    <Search/>
                </Stack>
            </Container>
            <div className={'content-card'}>
                <div className={'content-box'}>
                    <div className={'content-scrollable-box'}>
                        <Stack alignItems={"center"} justifyItems={"center"}>
                            <div className="wrapper" style={styles.Wrapper}>
                                <h1 className="page__title" style={styles.Header}>Пользовательское соглашение</h1>
                                <div className="inner-content p-contact__time" style={styles.InnerContent}>
                                    <p style={styles.Paragraph}>Использование Сайта Пользователем означает, что
                                        Пользователь
                                        принимает и обязуется соблюдать все нижеприведенные условия
                                        настоящего Соглашения.</p>
                                    <p style={styles.Paragraph}>Администрация Сайта http://doctor-arbitailo.ru оставляет
                                        за собой
                                        право вносить в Соглашение изменения, которые вступают в силу с
                                        момента публикации на этой странице. Дальнейшее использование
                                        вами Сайта http://doctor-arbitailo.ru после внесения подобных
                                        изменений означает ваше согласие с ними.</p>
                                    <p style={styles.Paragraph}>Текст действующего Соглашения всегда доступен по адресу
                                        Сайт
                                        http://doctor-arbitailo.ru/agreement/</p>
                                    <p style={styles.Paragraph}>Если Вы не согласны соблюдать настоящее Пользовательское
                                        соглашение, не используйте Сайт. В Вашей личной ответственности
                                        остается регулярный просмотр данной страницы для ознакомления с
                                        действующей редакцией Соглашения.</p>
                                    <p style={styles.Paragraph}>
                                        <strong>1. Основные понятия и определения</strong>
                                    </p>
                                    <p style={styles.Paragraph}>
                                        <strong>1.1.</strong>&nbsp;Сайт - совокупность размещенных в
                                        сети Интернет веб-страниц, объединенных единой темой, дизайном и
                                        единым адресным пространством домена http://doctor-arbitailo.ru.
                                        Стартовая страница Сайта размещена в сети Интернет по адресу:
                                        http://doctor-arbitailo.ru.</p>
                                    <p style={styles.Paragraph}>
                                        <strong>1.2.</strong>&nbsp;Пользователь Сайта (Пользователь) -
                                        любое лицо, осуществляющее доступ к Сайту посредством сети
                                        Интернет.</p>
                                    <p style={styles.Paragraph}>
                                        <strong>1.3.</strong>&nbsp;Контент – информация создаваемая и
                                        размещаемая администрацией сайте, в том числе в виде текстов,
                                        рецензий, отзывов, анонсов, фото, видео, в том числе новости и
                                        прочие материалы.</p>
                                    <p style={styles.Paragraph}>
                                        <strong>1.4.</strong>&nbsp;Владелец Сайта – ООО "Доктор
                                        Арбитайло"</p>
                                    <p style={styles.Paragraph}>
                                        <strong>1.5.</strong>&nbsp;Владелец Сайта и Пользователь Сайта в
                                        дальнейшем совместно именуются "Стороны", а по отдельности
                                        "Сторона".</p>
                                    <p style={styles.Paragraph}>
                                        <strong>1.6.</strong>&nbsp;Администрация Сайта (Администрация) -
                                        лица, уполномоченные Владельцем Сайта на осуществление
                                        управления Сайтом и иные действия, связанные с его
                                        использованием. Администрация Сайта действует от имени Владельца
                                        Сайта, если иное не будет указано отдельно.</p>
                                    <p style={styles.Paragraph}>
                                        <strong>2. Ограничение ответственности Администрации:</strong>
                                    </p>
                                    <p style={styles.Paragraph}>
                                        <strong>2.1.</strong>&nbsp;Используя информацию с Сайта,
                                        Пользователь осознает и принимает риски, связанные с ее
                                        возможной недостоверностью, а также с тем, что некоторая
                                        информация может показаться ему угрожающей, оскорбительной,
                                        клеветнической, заведомо ложной, грубой, непристойной. Если это
                                        произошло, Пользователь должен немедленно прекратить
                                        использовать Сайт.</p>
                                    <p style={styles.Paragraph}>
                                        <strong>2.2</strong>&nbsp;. Администрация не гарантирует, что
                                        программное обеспечение, сервера и компьютерные сети,
                                        используемые Сайтом свободны от ошибок и компьютерных вирусов.
                                        Если использование Сайта повлекло за собой утрату данных или
                                        порчу оборудования Администрация не несет за это
                                        ответственности.</p>
                                    <p style={styles.Paragraph}>
                                        <strong>2.3.</strong>&nbsp;Сайт и его сервисы, включая все
                                        скрипты, приложения, контент и оформление сайта поставляются как
                                        есть. Администрация отказывается от всяких гарантий того, что
                                        сайт или его сервисы могут подходить или не подходить для
                                        конкретных целей использования. Администрация не может
                                        гарантировать и не обещает никаких специфических результатов от
                                        использования сайта и(или) его сервисов.</p>
                                    <p style={styles.Paragraph}>
                                        <strong>2.4.</strong>&nbsp;Ни при каких обстоятельствах
                                        администрация сайта или ее представители не несут
                                        ответственности перед Пользователями или перед любыми третьими
                                        лицами за любой косвенный, случайный, неумышленный ущерб,
                                        включая упущенную выгоду или потерянные данные, вред чести,
                                        достоинству или деловой репутации, вызванный в связи с
                                        использованием сайта, содержимого сайта или иных материалов, к
                                        которым Пользователи или иные лица получили доступ с помощью
                                        сайта, даже если администрация предупреждала или указывала на
                                        возможность такого вреда.</p>
                                    <p style={styles.Paragraph}>
                                        <strong>2.5.</strong>&nbsp;Точка зрения Пользователей Сайта
                                        может не совпадать с мнением Администрации.</p>
                                    <p style={styles.Paragraph}>
                                        <strong>3. Администрация имеет право:</strong>
                                    </p>
                                    <p style={styles.Paragraph}>
                                        <strong>3.1.</strong>&nbsp;В любое время изменять оформление
                                        Сайта, его Контент, список сервисов, изменять или дополнять
                                        используемые скрипты, программное обеспечение и другие объекты,
                                        используемые или хранящиеся на Сайте, любые серверные приложения
                                        в любое время с предварительным уведомлением или без такового;
                                    </p>
                                    <p style={styles.Paragraph}>
                                        <strong>3.2.</strong>&nbsp;При необходимости отправлять
                                        пользователям по электронной почте сообщения, касающиеся
                                        использования Сайта. При этом Администрация никогда не будет
                                        запрашивать в таких письмах подтверждение сведений, содержащих
                                        личную информацию.</p>
                                    <p style={styles.Paragraph}>
                                        <strong>3.3.</strong>&nbsp;Изменять или модерировать любой
                                        Контент, нарушающий настоящее Соглашение, а также
                                        приостанавливать, ограничивать или прекращать доступ
                                        Пользователя ко всем или к любому из разделов или сервисов Сайта
                                        с предварительным уведомлением или без такового;</p>
                                    <p style={styles.Paragraph}>
                                        <strong>3.4.</strong>&nbsp;Устанавливать дополнительные
                                        ограничения на использование Сайта, а также изменять такие
                                        ограничения в любое время.</p>
                                    <p style={styles.Paragraph}>
                                        <strong>4. Пользователь имеет право:</strong>
                                    </p>
                                    <p style={styles.Paragraph}>
                                        <strong>4.1.</strong>&nbsp;Обращаться к Администрации Сайта с
                                        целью разрешения спорных вопросов или за помощью в использовании
                                        Сайта;</p>
                                    <p style={styles.Paragraph}>
                                        <strong>4.2.</strong>&nbsp;Бесплатно пользоваться всем Контентом
                                        сайта в личных целях, не сопряженных с целями получения
                                        коммерческой выгоды. См. подробнее правила использования
                                        Контента ниже.</p>
                                    <p style={styles.Paragraph}>
                                        <strong>5. Пользователь обязуется:</strong>
                                    </p>
                                    <p style={styles.Paragraph}>
                                        <strong>5.1.</strong>&nbsp;Соблюдать правила настоящего
                                        соглашения, а в частности - не производить запрещенных действий
                                        на Сайте и не нарушать политику цитирования, копирования и
                                        распространения Контента.</p>
                                    <p style={styles.Paragraph}>
                                        <strong>6. Настоящим Соглашением на Сайте запрещается:</strong>
                                    </p>
                                    <p style={styles.Paragraph}>
                                        <strong>6.1.</strong>&nbsp;Собирать и/или хранить личные данные
                                        о других пользователях для коммерческих целей. В частности,
                                        собирать адреса электронной почты или другую контактную
                                        информацию пользователей Сайта автоматизированными или иными
                                        способами, в том числе с целью несанкционированной рассылки
                                        почты (спама) или другой нежелательной информации;</p>
                                    <p style={styles.Paragraph}>
                                        <strong>6.2.</strong>&nbsp;Использовать автоматизированные
                                        скрипты (программы) для сбора информации и/или взаимодействия с
                                        Сайтом и его сервисами;</p>
                                    <p style={styles.Paragraph}>
                                        <strong>6.3.</strong>&nbsp;Запрашивать у других Пользователей
                                        пароли и другую идентификационную информацию для ее
                                        неправомерного использования, а также в коммерческих или
                                        корыстных целях;</p>
                                    <p style={styles.Paragraph}>
                                        <strong>6.4.</strong>&nbsp;Любым способом, в том числе путем
                                        взлома, пытаться получить доступ к чужому Аккаунту вопреки воле
                                        Пользователя, которому он принадлежит;</p>
                                    <p style={styles.Paragraph}>
                                        <strong>6.5.</strong>&nbsp;Использовать Сайт любым способом,
                                        который может помешать нормальному функционированию Сайта и его
                                        сервисов;</p>
                                    <p style={styles.Paragraph}>
                                        <strong>6.6.</strong>&nbsp;Домогаться, притеснять, оскорблять,
                                        назойливо преследовать или иначе злонамеренно доставлять
                                        беспокойство любому физическому или юридическому лицу,
                                        пользователю сайта;</p>
                                    <p style={styles.Paragraph}>
                                        <strong>7. Права на Контент, размещенный на Сайте</strong>
                                    </p>
                                    <p style={styles.Paragraph}>
                                        <strong>7.1</strong>&nbsp;. Все объекты, размещенные на Сайте, в
                                        том числе элементы дизайна, текст, графические изображения,
                                        иллюстрации, видео, скрипты, программы, музыка, звуки и другие
                                        объекты и их подборки (далее - Контент), являются объектами
                                        исключительных прав Администрации и других правообладателей, все
                                        права на эти объекты защищены;</p>
                                    <p style={styles.Paragraph}>
                                        <strong>7.2.</strong>&nbsp;Кроме случаев, установленных
                                        настоящими Правилами, а также действующим законодательством
                                        Российской Федерации, никакой Контент не может быть скопирован
                                        (воспроизведен), переработан, распространен, отображен во
                                        фрейме, опубликован, скачан, передан, продан или иным способом
                                        использован целиком или по частям, без предварительного
                                        разрешения правообладателя, кроме случаев, когда правообладатель
                                        явным образом выразил свое согласие на свободное использование
                                        материала любым лицом;</p>
                                    <p style={styles.Paragraph}>
                                        <strong>7.3.</strong>&nbsp;Использование Контента, к
                                        которому Пользователь получил доступ исключительно для
                                        личного некоммерческого использования, допускается при
                                        условии сохранения всех знаков авторства (копирайтов) или
                                        других уведомлений об авторстве, сохранения имени автора в
                                        неизменном виде, сохранении произведения в неизменном виде;
                                    </p>
                                    <p style={styles.Paragraph}>
                                        <strong>7.4.</strong>&nbsp;Любое использование Сайта или
                                        Контента, кроме разрешенного в настоящих Правилах или в случае
                                        явно выраженного согласия автора (правообладателя) на такое
                                        использование, без предварительного письменного разрешения
                                        правообладателя, категорически запрещено;</p>
                                    <p style={styles.Paragraph}>
                                        <strong>7.5</strong>&nbsp;. Если иное явным образом не
                                        установлено в настоящих Правилах, ничто в настоящих Правилах не
                                        может быть рассмотрено как передача исключительных прав.</p>
                                    <p style={styles.Paragraph}>
                                        <strong>8. Правила использования материалов, опубликованных на
                                            Сайте.</strong>
                                    </p>
                                    <p style={styles.Paragraph}>
                                        <strong>8.1.</strong>&nbsp;Настоящий пункт Пользовательского
                                        соглашения определяет правила, порядок и условия использования
                                        материалов, размещенных на Сайте.</p>
                                    <p style={styles.Paragraph}>
                                        <strong>8.2.</strong>&nbsp;Все материалы, исключительные права
                                        на которые принадлежат Администрации Сайта
                                        http://doctor-arbitailo.ru, могут быть воспроизведены в любых
                                        средствах массовой информации, на серверах сети Интернет или на
                                        любых иных носителях без каких-либо ограничений по объему и
                                        срокам публикации. Это разрешение в равной степени
                                        распространяется на газеты, журналы, радиостанции, телеканалы,
                                        сайты и страницы сети Интернет. Условием перепечатки и
                                        ретрансляции является ссылка на первоисточник
                                        http://doctor-arbitailo.ru.</p>
                                    <p style={styles.Paragraph}>
                                        <strong>8.3.</strong>&nbsp;Для Интернет-ресурсов обязательным
                                        условием любого вида цитирования является размещение активной
                                        гиперссылки на http://doctor-arbitailo.ru в начале материала, не
                                        закрытой от индексирования поисковыми системами.</p>
                                    <p style={styles.Paragraph}>
                                        <strong>8.4.</strong>&nbsp;При воспроизведении материалов не
                                        допускается переработка их оригинального текста. Сокращение или
                                        перекомпоновка частей материала допускается, но только в той
                                        мере, в какой это не приводит к искажению его смысла.</p>
                                    <p style={styles.Paragraph}>
                                        <strong>8.5.</strong>&nbsp;При использовании в дайджестах прессы
                                        материалов не допускается их переработка в той мере, в какой она
                                        приводит к искажению их смысла.</p>
                                    <p style={styles.Paragraph}>
                                        <strong>8.6.</strong>&nbsp;Ответственность за искажение смысла
                                        материалов, возникшее вследствие их неточного воспроизведения,
                                        лежит на Пользователе.</p>
                                    <p style={styles.Paragraph}>
                                        <strong>8.7.</strong>&nbsp;Запрещено включение в RSS-рассылки
                                        сообщений, полностью дублирующих материалы Сайта.</p>
                                    <p style={styles.Paragraph}>
                                        <strong>8.8.</strong>&nbsp;Если в поле ALT или в подписи к
                                        какой-либо фотографии или иллюстрации указано имя автора или
                                        правообладателя, по вопросам их воспроизведения следует
                                        обращаться непосредственно к нему.</p>
                                    <p style={styles.Paragraph}>
                                        <strong>9. Используя Сайт, Пользователь обязуется не нарушать
                                            или пытаться нарушать информационную безопасность Сайта, что
                                            включает в себя:</strong>
                                    </p>
                                    <p style={styles.Paragraph}>
                                        <strong>9.1.</strong>&nbsp;доступ к данным, не предназначенным
                                        для данного Пользователя или вход в систему под логином, не
                                        принадлежащем данному Пользователю;</p>
                                    <p style={styles.Paragraph}>
                                        <strong>9.2.</strong>&nbsp;попытки проверить уязвимость системы
                                        безопасности Сайта, нарушение процедуры регистрации и
                                        авторизации без разрешения Администрации;</p>
                                    <p style={styles.Paragraph}>
                                        <strong>9.3.</strong>&nbsp;попытки создать помехи в
                                        использовании Сайта другим Пользователям, что включает в себя
                                        распространение компьютерных вирусов, порчу данных, постоянную
                                        рассылку повторяющейся информации, пересылку электронной почты
                                        через сервер сайта, одновременную отправку большого количества
                                        электронной почты и/или запросов к Сайту с целью намеренно
                                        вывести сервер сайта из строя и тому подобные действия,
                                        выходящие за рамки нормального целевого использования Сайта, и
                                        могущие умышленно или по неосторожности повлечь сбои в его
                                        работе;</p>
                                    <p style={styles.Paragraph}>
                                        <strong>9.4.</strong>&nbsp;рассылку пользователям Сайта
                                        материалов, на которые они не давали своего согласия, "спама",
                                        любых писем и рекламы без разрешения Администрации;</p>
                                    <p style={styles.Paragraph}>
                                        <strong>9.5.</strong>&nbsp;имитацию и/или подделку любого
                                        заголовка пакета TCP/IP или любой части заголовка в любом
                                        электронном письме или размещенном на Сайте материале;</p>
                                    <p style={styles.Paragraph}>
                                        <strong>9.6.</strong>&nbsp;использование или попытки
                                        использования любого программного обеспечения, или процедуры для
                                        навигации или поиску на Сайте, кроме встроенной в Сайт поисковой
                                        машины и традиционных и общедоступных браузеров
                                        (MicrosoftInternetExplorer, MozillaFirefox и других подобных).
                                    </p>
                                    <p style={styles.Paragraph}>
                                        <strong>9.7.</strong>&nbsp;НАРУШЕНИЕ БЕЗОПАСНОСТИ СИСТЕМЫ ИЛИ
                                        КОМПЬЮТЕРНОЙ СЕТИ ВЛЕЧЕТ ЗА СОБОЙ ГРАЖДАНСКУЮ И УГОЛОВНУЮ
                                        ОТВЕТСТВЕННОСТЬ. АДМИНИСТРАЦИЯ БУДЕТ РАССЛЕДОВАТЬ ВСЕ СЛУЧАИ
                                        ВОЗМОЖНОГО НАРУШЕНИЯ БЕЗОПАСНОСТИ СО СТОРОНЫ ПОЛЬЗОВАТЕЛЕЙ САЙТА
                                        В СОТРУДНИЧЕСТВЕ С КОМПЕТЕНТНЫМИ ОРГАНАМИ С ЦЕЛЬЮ ПРЕСЕЧЕНИЯ
                                        ПОДОБНОЙ ЗЛОНАМЕРЕННОЙ ДЕЯТЕЛЬНОСТИ.
                                    </p>
                                    <p style={styles.Paragraph}>
                                        <strong>10. УСЛОВИЯ ОБРАБОТКИ ПЕРСОНАЛЬНЫХ ДАННЫХ</strong>
                                    </p>
                                    <p style={styles.Paragraph}>Обработка персональных данных на Сайте осуществляется с
                                        соблюдением принципов и правил, предусмотренных Положением и
                                        законодательством РФ.</p>
                                    <p style={styles.Paragraph}>
                                        <strong>10.1.</strong>&nbsp;Обработка персональных данных Пользователей Сайта
                                        осуществляется исключительно в целях предоставления Пользователю
                                        возможности взаимодействовать с Сайтом.</p>
                                    <p style={styles.Paragraph}>
                                        <strong>10.2.</strong>&nbsp;Источником информации обо всех персональных данных
                                        Пользователя является непосредственно сам Пользователь.</p>
                                    <p style={styles.Paragraph}>
                                        <strong>10.3.</strong>&nbsp;Согласно законодательству РФ, администрация сайта не
                                        осуществляет обработку специальных категорий персональных
                                        данных, касающихся расовой, национальной принадлежности,
                                        политических взглядов, религиозных или философских убеждений,
                                        состояния здоровья, интимной жизни.</p>
                                    <p style={styles.Paragraph}>
                                        <strong>10.4.</strong>&nbsp;В случае достижения цели обработки персональных
                                        данных
                                        Администрация сайта прекращает обработку персональных данных и
                                        уничтожить персональные данные в срок, не превышающий 30 рабочих
                                        дней с даты достижения цели обработки персональных данных, если
                                        иное не предусмотрено договором, стороной которого является
                                        субъект персональных данных.</p>
                                    <p style={styles.Paragraph}>
                                        <strong>10.5.</strong>&nbsp;Администрация сайта обеспечивает конфиденциальность
                                        и
                                        безопасность персональных данных при их обработке
                                        в соответствии с требованиями законодательства РФ.</p>
                                    <p style={styles.Paragraph}>Лица, осуществляющие обработку персональных данных,
                                        обязаны
                                        соблюдать требования регламентирующих документов Оператора в
                                        части обеспечения конфиденциальности и безопасности персональных
                                        данных.</p>
                                    <p style={styles.Paragraph}>
                                        <strong>10.6.</strong>&nbsp;По истечении вышеуказанного срока хранения
                                        персональных
                                        данных Пользователя персональные данные Пользователя удаляются
                                        автоматически заданным алгоритмом, который задает Администрация
                                        сайта</p>
                                    <p style={styles.Paragraph}>
                                        <strong>10.7.</strong>&nbsp;Блокирование персональных данных на Сайте
                                        осуществляется на
                                        основании письменного заявления от субъекта персональных
                                        данных.</p>
                                    <p style={styles.Paragraph}>
                                        <strong>10.8.</strong>&nbsp;Уничтожение персональных данных осуществляется путем
                                        стирания информации, в ходе которого становится невозможным
                                        восстановить содержание персональных данных.</p>
                                    <p style={styles.Paragraph}>
                                        <strong>10.9.</strong>&nbsp;Администрация сайта при обработке персональных
                                        данных
                                        принимает необходимые правовые, организационные и технические
                                        меры или обеспечивать их принятие для защиты персональных данных
                                        от неправомерного или случайного доступа к ним, уничтожения,
                                        изменения, блокирования, копирования, предоставления,
                                        распространения персональных данных, а также от иных
                                        неправомерных действий в отношении персональных данных.</p>
                                    <p style={styles.Paragraph}>
                                        <strong>10.10.</strong>&nbsp;Обеспечение безопасности персональных данных
                                        достигается,
                                        в частности:</p>
                                    <ul style={styles.List}>
                                        <li style={styles.ListItem}>
                                        <span style={styles.Span}>
                                            определением угроз безопасности персональных данных при их
                                    обработке в информационных системах персональных данных;
                                        </span>
                                        </li>
                                        <li style={styles.ListItem}>
                                        <span style={styles.Span}>
                                            применением организационных и технических мер по обеспечению
                                    безопасности персональных данных при их обработке в
                                    информационных системах персональных данных, необходимых для
                                    выполнения требований к защите персональных данных;
                                        </span>
                                        </li>
                                        <li style={styles.ListItem}>
                                        <span style={styles.Span}>
                                            применением прошедших в установленном порядке процедуру оценки
                                    соответствия средств защиты информации;
                                        </span>
                                        </li>
                                        <li style={styles.ListItem}>
                                        <span style={styles.Span}>
                                            оценкой эффективности принимаемых мер по обеспечению
                                    безопасности персональных данных до ввода в эксплуатацию
                                    информационной системы персональных данных;
                                        </span>
                                        </li>
                                        <li style={styles.ListItem}>
                                        <span style={styles.Span}>
                                            учетом машинных носителей персональных данных;
                                        </span>
                                        </li>
                                        <li style={styles.ListItem}>
                                        <span style={styles.Span}>
                                            обнаружением фактов несанкционированного доступа к персональным
                                    данным и принятием мер;
                                        </span>
                                        </li>
                                        <li style={styles.ListItem}>
                                        <span style={styles.Span}>
                                            восстановлением персональных данных, модифицированных или
                                    уничтоженных вследствие несанкционированного доступа к ним;
                                        </span>
                                        </li>
                                        <li style={styles.ListItem}>
                                        <span style={styles.Span}>
                                            установлением правил доступа к персональным данным,
                                    обрабатываемым в информационной системе персональных данных, а
                                    также обеспечением регистрации и учета всех действий,
                                    совершаемых с персональными данными в информационной системе
                                    персональных данных;
                                        </span>
                                        </li>
                                        <li style={styles.ListItem}>
                                        <span style={styles.Span}>
                                            контролем за принимаемыми мерами по обеспечению безопасности
                                    персональных данных и уровня защищенности информационных систем
                                    персональных данных.
                                        </span>
                                        </li>
                                    </ul>
                                    <p style={styles.Paragraph}>
                                        <strong>10.11.</strong>&nbsp;Система защиты персональных данных должна
                                        соответствовать
                                        требованиям постановления Правительства РФ от 1 ноября 2012 г. №
                                        1119 «Об утверждении требований к защите персональных данных при
                                        их обработке в информационных системах персональных данных».</p>
                                    <p style={styles.Paragraph}>
                                        <strong>10.12.</strong>&nbsp;Основными методами и способами защиты информации в
                                        информационных системах персональных данных Пользователей
                                        являются методы и способы защиты информации от
                                        несанкционированного, в том числе случайного, доступа к
                                        персональным данным, результатом которого может стать
                                        уничтожение, изменение, блокирование, копирование,
                                        распространение персональных данных, а также иных
                                        несанкционированных действий.</p>
                                    <p style={styles.Paragraph}>
                                        <strong>10.13.</strong>&nbsp;Выбранные и реализованные методы и способы защиты
                                        информации на Сайте должны обеспечивать нейтрализацию
                                        предполагаемых угроз безопасности персональных данных при их
                                        обработке.</p>
                                    <p style={styles.Paragraph}>
                                        <strong>10.14.</strong>&nbsp;Все сотрудники Администрации сайта, осуществляющие
                                        обработку персональных данных, обязаны хранить тайну о
                                        сведениях, содержащих персональные данные, в соответствии с
                                        Положением, требованиями законодательства РФ.</p>
                                    <p style={styles.Paragraph}>
                                        <strong>10.15.</strong>&nbsp;Администрация прилагает все
                                        возможные усилия для того, чтобы избежать несанкционированного
                                        использования персональной информации Пользователей.</p>
                                    <p style={styles.Paragraph}>
                                        <strong>10.16.</strong>&nbsp;Однако Администрация не несет
                                        ответственности за возможное нецелевое использование
                                        персональной информации Пользователей, произошедшее из-за:</p>
                                    <p style={styles.Paragraph}>
                                        <strong>10.17.</strong>&nbsp;технических неполадок в программном
                                        обеспечении, серверах или компьютерных сетях, находящихся вне
                                        контроля Администрации;</p>
                                    <p style={styles.Paragraph}>
                                        <strong>10.18.&nbsp;</strong>перебоев в работе Сайта, связанных
                                        с намеренным или ненамеренным использованием Сайта не по
                                        назначению третьими лицами, описанным в разделе "Информационная
                                        безопасность Сайта";</p>
                                    <p style={styles.Paragraph}>
                                        <strong>10.19.</strong>&nbsp;Используя Сайт, Пользователь
                                        соглашается и принимает, что Администрация оставляет за собой
                                        право использовать его персональную информацию анонимно и в
                                        обобщенном виде для статистических целей, а также для таргетинга
                                        рекламы, размещаемой на Сайте.</p>
                                    <p style={styles.Paragraph}>
                                        <strong>10.20.&nbsp;</strong>Используя Сайт и любые его сервисы,
                                        Пользователь дает свое согласие Администрации сайта на
                                        использование, хранение и обработку персональных данных: ФИО,
                                        телефон, адрес электронной почты и иные индивидуальные средства
                                        коммуникации, указанных при заполнении на сайте всех форм, а
                                        именно:</p>
                                    <ul style={styles.List}>
                                        <li style={styles.ListItem}>
                                        <span style={styles.Span}>
                                            использовании сервисов обратной связи с Администрацией
                                    сайта;
                                        </span>
                                        </li>
                                    </ul>
                                    <p style={styles.Paragraph}>В целях обеспечения реализации требований
                                        законодательства в
                                        области обработки персональных данных Администрация сайта
                                        может:</p>
                                    <ul style={styles.List}>
                                        <li style={styles.ListItem}>
                                        <span style={styles.Span}>
                                            осуществлять обработку персональных данных путем сбора,
                                    хранения, систематизации, накопления, изменения, уточнения,
                                    использования, распространения, обезличивания, блокирования,
                                    уничтожения персональных данных;
                                        </span>
                                        </li>
                                        <li style={styles.ListItem}>
                                        <span style={styles.Span}>
                                            использовать автоматизированный способ обработки персональных
                                    данных с передачей по сети Интернет;
                                        </span>
                                        </li>
                                        <li style={styles.ListItem}>
                                        <span style={styles.Span}>
                                            осуществлять трансграничную передачу персональных данных.
                                        </span>
                                        </li>
                                    </ul>
                                    <p style={styles.Paragraph}>В своей деятельности по обработке персональных данных
                                        Администрация сайта руководствуется действующим
                                        законодательством о персональных данных и иными внутренними
                                        локальными нормативными актами, посвященными вопросам обработки
                                        персональных данных и устанавливающим правила доступа к
                                        персональным данным.</p>
                                    <p style={styles.Paragraph}>Администрация сайта принимает на себя обязательства по
                                        обеспечению целостности и сохранности персональных данных
                                        субъектов персональных данных. Для данных целей Администрация
                                        сайта принимает необходимые организационные и технические меры
                                        для защиты персональных данных, используя при этом общепринятые
                                        методы и средства безопасности для обеспечения защиты информации
                                        от неправомерного или случайного доступа к ней, уничтожения,
                                        изменения, блокирования, копирования, несанкционированного
                                        распространения, а также иных неправомерных действий со стороны
                                        третьих лиц.</p>
                                    <p style={styles.Paragraph}>Контроль исполнения требований по вопросам обработки
                                        персональных
                                        данных осуществляется ответственным за организацию обработки и
                                        обеспечение безопасности персональных данных Администрации
                                        сайта. Ответственность должностных лиц Администрации сайта
                                        имеющих доступ к персональным данным, за невыполнение требований
                                        норм, регулирующих обработку и защиту персональных данных,
                                        определяется в соответствии с законодательством Российской
                                        Федерации.</p>
                                    <p style={styles.Paragraph}>
                                        <strong>10.21.</strong>&nbsp;Администрация обязуется не
                                        предоставлять никакой персональной информации о Пользователях
                                        частным лицами и организациям, заявляющим о возможном нецелевом
                                        использовании подобной информации (рассылки несанкционированной
                                        рекламы, "спама", предоставлении информации другим лицам и тому
                                        подобное), за исключением случаев, предусмотренных действующим
                                        законодательством РФ.</p>
                                    <p style={styles.Paragraph}>
                                        <strong>10.22.</strong>&nbsp;Однако Администрация не отвечает за
                                        возможное нецелевое использование информации с Сайта
                                        зарегистрированными пользователями или иными лицами и/или
                                        организациями, которое произошло без уведомления Администрации с
                                        нарушением или без информационной безопасности Сайта.</p>
                                    <p style={styles.Paragraph}>
                                        <strong>10.23.</strong>&nbsp;Права на Информацию, размещенную на
                                        Сайте, принадлежат Владельцам или иным правообладателям
                                        (обладатели информации). Информация доступна на Сайте
                                        исключительно в режиме цитирования в информационных целях.
                                        Пользователи не получают никаких прав на использование
                                        Информации вне рамок возможностей, предоставляемых Сайтом, и
                                        несут полную ответственность перед правообладателем за их
                                        неправомерное использование.</p>
                                    <p style={styles.Paragraph}>
                                        <strong>11. Общие положения</strong>
                                    </p>
                                    <p style={styles.Paragraph}>
                                        <strong>11.1.</strong>&nbsp;Настоящее Соглашение носит
                                        исчерпывающий характер между Пользователями и Администрацией
                                        относительно порядка использования Сайта и его сервисов и
                                        заменяют собой все предыдущие соглашения между Пользователями и
                                        Администрацией;</p>
                                    <p style={styles.Paragraph}>
                                        <strong>11.2.</strong>&nbsp;Настоящее Соглашение регулируется и
                                        толкуется в соответствии с законодательством Российской
                                        Федерации. Вопросы, не урегулированные Соглашением, подлежат
                                        разрешению в соответствии с законодательством Российской
                                        Федерации.</p>
                                    <p style={styles.Paragraph}>
                                        <strong>11.3.</strong>&nbsp;Если по тем или иным причинам одна
                                        или несколько норм настоящих Правил является недействительной
                                        или не имеющей юридической силы, это не оказывает влияния на
                                        действительность или применимость остальных норм.</p>
                                </div>
                            </div>
                        </Stack>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TermsOfUse;