import React from "react";
import {Rating} from "@mui/material";
import FavoriteIcon from "@mui/icons-material/Favorite";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import {Appointment} from "../../../../../types/Appointment";

interface RatingAppointmentProps {
    changeRating: (event:  React.SyntheticEvent, newValue: number|null) => void;
    appointment: Appointment;
    precision?: number;
}

const RatingAppointment: React.FC<RatingAppointmentProps> = (
    {
        appointment,
        precision = 0.5,
        changeRating
    }
) => {
    if (appointment.visited) {
        return (
            <Rating name={`user-rating-${appointment.id}`}
                    value={appointment.ratingService}
                    precision={precision}
                    readOnly={!appointment.visited || appointment.ratingService > 0}
                    onChange={changeRating}
                    sx={{
                        '& .MuiRating-iconFilled': {
                            color: '#D23731',
                        },
                        '& .MuiRating-iconHover': {
                            color: '#D23731',
                        }
                    }}
                    icon={<FavoriteIcon fontSize="inherit"/>}
                    emptyIcon={<FavoriteBorderIcon fontSize="inherit"/>}/>
        )
    } else {
        return (
            <></>
        )
    }
}

export default RatingAppointment;