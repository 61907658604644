import React from "react";
import {CardDoctorProps} from "./types";
import {Grid} from "@mui/material";
import InfoAboutDoctor from "./InfoAboutDoctor";
import AvailableDates from "./AvailableDates";

const CardDoctor: React.FC<CardDoctorProps> = (props) => {

    return (
        <Grid item md={12} width={'100%'}>
            <div className={'box__shadow'}>
                <div className={'card-content'}>
                    <div className={'card-doctor'}>
                        <InfoAboutDoctor {...props}/>
                        <AvailableDates {...props}/>
                    </div>
                </div>
            </div>
        </Grid>
    )
}

export default CardDoctor;