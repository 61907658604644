import React from "react";
import {Grid, Typography} from "@mui/material";
import {Center} from "../../../types/Center";
import {StyledPaper} from "../../styled";

type ItemFC = {
    mis: Center,
    selectCenter: string,
    selectCard: (event: React.SyntheticEvent, mis: Center) => void
}

//TODO переименовать думаю лучше.
const Item: React.FC<ItemFC> = (
    {
        mis,
        selectCenter,
        selectCard
    }
) => {
    return (
        <Grid item xs={12} sm={6} md={3} mt={1}>
            <StyledPaper
                selected={selectCenter === mis.id}
                elevation={2}
                onClick={(event) => selectCard(event, mis)}
            >
                <Typography
                    className={'mis__type'}
                >
                    {mis.centerType}
                </Typography>
                <Typography
                    className={'mis__name'}
                >
                    {mis.name}
                </Typography>
                <Typography
                    className={'mis__address'}
                >
                    {mis.address}
                </Typography>
            </StyledPaper>
        </Grid>
    )
}

export default Item;