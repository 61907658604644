import React from "react";
import {ListItemIcon, MenuItem} from "@mui/material";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import {useHistory} from "react-router-dom";
import {RouteNames} from "../../router";

const SignInItem: React.FC = () => {

    const history = useHistory()

    const handleClickItem = () => {
        history.push(RouteNames.SIGNIN)
    }

    return (
        <MenuItem onClick={handleClickItem}>
            <ListItemIcon>
                <AddCircleOutlineIcon/>
            </ListItemIcon>
            Войти
        </MenuItem>
    )
}

export default SignInItem;