import * as React from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import {Password} from "../../components/FormComponents";
import {useHistory} from "react-router-dom";
import {useSnackbar} from 'notistack';
import FormField from "../../components/FormComponents/FormField";
import $api from "../../http";
import {checkFormBeforeSubmit} from "../../components/FormComponents/Controller";
import {useTimer} from "react-timer-hook";
import {checkConfirmationCode} from "../../components/FormComponents/Validations/Validations";
import {useEffect} from "react";

const ForgotPasswordPage: React.FC = () => {
    const history = useHistory();
    const {enqueueSnackbar} = useSnackbar()
    const [validForm, setValidForm] = React.useState<object>(
        {
            login: false,
            password: false,
            repeatPassword: false
        }
    );
    const [correctCode, setCorrectCode] = React.useState<object>({confirmationCode: false});
    const {
        seconds,
        minutes,
        restart,
        isRunning
    } = useTimer({
        expiryTimestamp: new Date(new Date().setSeconds(new Date().getSeconds() + 600)),
        autoStart: false
    });
    const [blockConfirmButton, setBlockConfirmButton] = React.useState<boolean>(false);

    function sleep(ms: number) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);

        if (!isRunning) {
            $api.post('auth-help/generate-reset-code',
                {
                    login: data.get("login"),
                    newPassword: null,
                    confirmationCode: null
                },
                {
                    headers: {'Content-Type': 'application/json'}
                })
                .then(async (responseData) => {
                    if (responseData.status === 200) {
                        const time = new Date();
                        time.setSeconds(time.getSeconds() + 600);
                        restart(time);
                        enqueueSnackbar(responseData.data, {variant: "success"})
                    }
                }).catch((error) => {
                    enqueueSnackbar(error.response.data, {variant: "error"});
                    console.error(error);
                });
        } else {
            setBlockConfirmButton(true);
            $api.post('auth-help/reset-password',
                {
                    login: data.get("login"),
                    newPassword: data.get("password"),
                    confirmationCode: data.get("confirmationCode")
                },
                {
                    headers: {'Content-Type': 'application/json'}
                }).then(async (responseData) => {
                if (responseData.status === 200) {
                    enqueueSnackbar(responseData.data, {variant: "success"});
                    await sleep(2000);
                    history.goBack();
                }
            }).catch((error) => {
                setBlockConfirmButton(false);
                enqueueSnackbar(error.response.data, {variant: "error"});
                console.error(error);
            });
        }
    };

    useEffect(() => {
        document.title = "Восстановление пароля";
    }, [])

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline/>
            <Box
                sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Typography component="h1" variant="h5">
                    {"Восстановление пароля"}
                </Typography>
                <Box component="form" onSubmit={handleSubmit} noValidate sx={{mt: 1}}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12}>
                            <FormField
                                label={"Логин"}
                                required={true}
                                validForm={validForm}
                                setValidForm={setValidForm}
                                name={"login"}
                                readOnly={isRunning}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Password
                                label={"Новый пароль"}
                                required={true}
                                validForm={validForm}
                                setValidForm={setValidForm}
                                name={"password"}
                                readOnly={isRunning}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Password
                                label={"Повторите пароль"}
                                required={true}
                                validForm={validForm}
                                setValidForm={setValidForm}
                                name={"repeatPassword"}
                                repeatPassword
                                readOnly={isRunning}
                            />
                        </Grid>
                        {isRunning ?
                            <>
                                <Grid item xs={12} sm={12}>
                                    <FormField required={true} label={'Код подтверждения'} name={'confirmationCode'}
                                               validForm={correctCode} setValidForm={setCorrectCode}
                                               additionalCheck={checkConfirmationCode} endAdornment={<div>{(Math.log(minutes) * Math.LOG10E + 1 | 0) === 2 ? "" : 0}{minutes}:{(Math.log(seconds) * Math.LOG10E + 1 | 0) === 2 ? "" : 0}{seconds}</div>}/>
                                </Grid>
                            </>
                            : <></>}
                        <Grid item xs={12} sm={6}>
                            <Link underline="none" color="inherit" href={"/sign-in"}>
                                <Button
                                    fullWidth
                                    variant="outlined"
                                    color="secondary"
                                >
                                    {"Отмена"}
                                </Button>
                            </Link>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                color={"secondary"}
                                disabled={checkFormBeforeSubmit(validForm) || (checkFormBeforeSubmit(correctCode) && isRunning) || blockConfirmButton}
                            >
                                {isRunning ? "Сменить пароль" : "Получить код"}
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </Container>
    );
}

export default ForgotPasswordPage;