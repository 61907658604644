import {Box, BoxProps, styled} from "@mui/material";

interface StyledBoxScrollableProps extends BoxProps {
    height?: number | string,
    marginTop?: number | string,
}

const StyledBoxScrollable = styled(Box, {
    shouldForwardProp: (prop) => prop !== 'success',
})<StyledBoxScrollableProps>(
    ({
         height,
         marginTop,
         theme
     }) => ({
        height: height,
        overflowY: 'auto',
        marginTop: marginTop ?? '3vh',
        scrollbarWidth: "thin",
        scrollbarColor: `${theme.palette.secondary.dark} #e0e0e0`,
    }))

export default StyledBoxScrollable;