import {
    Button,
    ListItemText, Menu,
    MenuItem,
    MenuList,
} from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import React from "react";
import $api from "../../../../../http";
import {useSnackbar} from "notistack";
import {File} from "../../../../../types/Appointment";

interface ButtonMenuProps {
    options: Array<File>;
    quantityFiles: number;
}

export default function ButtonMenu(props: ButtonMenuProps): JSX.Element {
    const {options, quantityFiles} = props;

    const {enqueueSnackbar} = useSnackbar();

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const isOpen = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    }

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleMenuItemClick = (
        id: number,
    ) => {
        $api.get(`appointment/book-file/${id}`,
            {
                responseType: "blob"
            }).then(async function (response) {
            if (response.status === 200) {
                console.log(response);
                const url = window.URL.createObjectURL(new Blob([response.data], {type: response.data.type}));
                window.open(url);

                window.URL.revokeObjectURL(url);
            }
        }).catch((error) => {
            enqueueSnackbar(error.response.data, {variant: "error"});
            console.error(error);
        });
        setAnchorEl(null);
    };

    return (
        <>
            <Button variant="contained"
                    onClick={handleClick}
                    endIcon={<ArrowDropDownIcon/>}
                    disabled={!quantityFiles}
                    sx={{
                        textTransform: "unset",
                        p: 0.5,
                        backgroundColor: '#6666E2',
                        color: 'primary.main',
                        borderRadius: 16,
                    }}>
                Документы
            </Button>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={isOpen}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                <MenuList id="split-button-menu" autoFocusItem>
                    {options?.map((option) => (
                        <MenuItem
                            key={option.id}
                            onClick={() => handleMenuItemClick(option.id)}
                        >
                            <ListItemText>{option.fileName}</ListItemText>
                        </MenuItem>
                    ))}
                </MenuList>
            </Menu>
        </>
    )
}