import React from "react";
import FormField from "./FormField";
import {GlobalChangeStateObject} from "../../types/Global";

const checkValidPassword = (value: string) => {
    const lengthPassword = 6;

    const reg = /^(?=.*[A-Za-zА-Яа-я])(?=.*\d)[A-Za-zА-Яа-я\d\D\W]{6,}$/;
    const test = reg.test(value);

    if (test) {
        return {correctCurrent: true, message: ""}
    } else {
        return {
            correctCurrent: false,
            message: `Пароль должен состоять не менее чем из ${lengthPassword} символов и должен содержать в себе минимум одну букву и цифру`
        }
    }
};

const checkRepeatPassword = (value: string) => {
    //TODO без ts-ignore не работает, нужно будет подумать потом, в чём дело.

    // @ts-ignore
    const password = document.getElementById("password").value;

    return ((value===password) ? {
        correctCurrent: true,
        message: ""
    } : {
        correctCurrent: false,
        message: "Пароли не совпадают"
    })
}

interface PasswordProps {
    defaultValue?: string;
    edit?: boolean;
    readOnly?: boolean;
    changeState?: GlobalChangeStateObject;
    label: string;
    required?: boolean;
    validForm: object;
    setValidForm: React.SetStateAction<object>;
    name: string;
    repeatPassword?: boolean;
    disabled?: boolean
}

function Password(props: PasswordProps) {
    const {
        label,
        required,
        validForm,
        setValidForm,
        name,
        repeatPassword,
        edit,
        readOnly,
        changeState,
        defaultValue,
        disabled
    } = props;

    const [showPassword, setShowPassword] = React.useState<boolean>(false);

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    }

    return (
        <FormField
            defaultValue={defaultValue}
            edit={edit}
            readOnly={readOnly}
            changeState={changeState}
            label={label}
            required={required}
            validForm={validForm}
            setValidForm={setValidForm}
            name={name}
            disabled={disabled}
            showPassword={showPassword}
            handleClickShowPassword={handleClickShowPassword}
            additionalCheck={repeatPassword ? checkRepeatPassword : checkValidPassword}
        />
    )
}


export {
    Password,
};