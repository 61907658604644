import React from "react";
import {Grid, Stack} from "@mui/material";
import {Speciality} from "../../../types/Speciality";
import {StyledSpecialityName, StyledSpecialityPlaceCount} from "./StyledComponents";
import StyledSpecialityChildrenType from "./StyledComponents/StyledSpecialityChildrenType";
import {StyledPaper} from "../../styled";

type DirectionItemFC = {
    specialty: Speciality,
    selectSpeciality: (name: string, id: string) => void,
    selectedSpeciality?: string
}

const DirectionItem: React.FC<DirectionItemFC> = (
    {
        specialty,
        selectSpeciality,
        selectedSpeciality
    }
) => {
    return (
        <Grid item xs={12} sm={6} md={4}>
            <StyledPaper
                height={60}
                selected={selectedSpeciality === String(specialty.id)}
                elevation={4}
                onClick={() => {
                    selectSpeciality(specialty.name, String(specialty.id))
                }}>
                <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <StyledSpecialityName>
                        {specialty.name}
                    </StyledSpecialityName>
                    <StyledSpecialityPlaceCount>
                        {specialty.placeCount} мест
                    </StyledSpecialityPlaceCount>
                </Stack>
                <StyledSpecialityChildrenType>
                    {specialty.childrenType !== null ? (specialty.childrenType ? "детский" : "взрослый") : <></>}
                </StyledSpecialityChildrenType>
            </StyledPaper>
        </Grid>
    )
}

export default DirectionItem;