import * as React from "react";

interface changeValidFormParams {
    field: string,
    fieldValue: boolean,
    validForm?: object,
    setValidForm?: React.SetStateAction<any>
}

const changeValidForm = (params: changeValidFormParams) => {
    const {field, fieldValue, validForm, setValidForm} = params;

    setValidForm({
        ...validForm,
        [field]: fieldValue
    })
}

// Если у формы какие-то поля не проходят проверку, то возращает true.
const checkFormBeforeSubmit = (validForm: object) => Object.values(validForm).includes(false)

export {
    changeValidForm,
    checkFormBeforeSubmit
}