import React from "react";
import {InputLabel, OutlinedInput} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import {IMaskInput} from "react-imask";
import {changeValidForm} from "./Controller";
import {checkEmptyField} from "./Validations";
import ErrorMessage from "./ErrorMessage";
import {stateErrorMessage} from "./FormComponentProps";
import {GlobalChangeStateObject} from "../../types/Global";

interface CustomProps {
    onChange: (event: { target: { name: string; value: string } }) => void;
    name: string;
}

interface PhoneNumberFieldProps {
    readOnly?: boolean,
    edit?: boolean,
    defaultValue?: string,
    required?: boolean,
    validForm?: object,
    setValidForm?: React.SetStateAction<object>,
    changeState?: GlobalChangeStateObject,
    name?: string,
    additionalCheck?: ((value: string) => stateErrorMessage),
    rollbackEnabled?: boolean,
    disabled?: boolean
}

const PhoneNumberMaskCustom = React.forwardRef<HTMLElement, CustomProps>(
    function PhoneNumberMaskCustom(props, ref) {
        const {onChange, ...other} = props;
        return (
            <IMaskInput
                {...other}
                //@ts-ignore
                mask="+7 (###) ###-##-##"
                definitions={{
                    '#': /[0-9]/,
                }}
                //@ts-ignore
                inputRef={ref}
                onAccept={(value: any) => onChange({target: {name: props.name, value}})}
                overwrite
            />
        );
    },
);

export default function PhoneNumber(props: PhoneNumberFieldProps) {
    const {
        required,
        setValidForm,
        validForm,
        additionalCheck,
        defaultValue,
        changeState,
        edit,
        readOnly,
        rollbackEnabled,
        disabled
    } = props;

    const [phoneNumber, setPhoneNumber] = React.useState<string>(defaultValue ?? "");
    const [correct, setCorrect] = React.useState<boolean>(true);
    const [message, setMessage] = React.useState<string>("");

    const checkPhoneNumber = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value

        if (required || edit) {
            const {correctCurrent, message} = checkEmptyField(value, additionalCheck);

            changeValidForm({
                field: 'phoneNumber',
                fieldValue: correctCurrent,
                validForm,
                setValidForm
            });

            setMessage(message);
            setCorrect(correctCurrent);
        }

        if (changeState) {
            changeState('phoneNumber', value)
        }
        setPhoneNumber(value);
    };

    return (
        <FormControl variant="outlined" fullWidth>
            <InputLabel
                required={required}
                htmlFor="formatted-text-mask-input"
                color="secondary"
                variant={"outlined"}
                disabled={disabled}
            >Номер телефона
            </InputLabel>
            <OutlinedInput
                readOnly={readOnly}
                value={rollbackEnabled ? defaultValue : phoneNumber}
                onChange={checkPhoneNumber}
                name="phoneNumber"
                id="formatted-text-mask-input"
                inputComponent={PhoneNumberMaskCustom as any}
                label="Номер телефона"
                placeholder={"+7 (XXX) XXX-XX-XX"}
                color="secondary"
                disabled={disabled}
                endAdornment={
                    <ErrorMessage correct={correct} message={message}/>
                }
            />
        </FormControl>
    )
};