import React, { useContext } from "react";
import {ApplicationsContext} from "./ApplicationsContext";

interface UseApplicationsResult {
    application?: string;
    setApplication?: React.Dispatch<React.SetStateAction<string>>;
    changeApplication: (newApplication: string) => void;
}

type UseApplications = () => UseApplicationsResult;

const useApplications: UseApplications = () => {
    const { application, setApplication } = useContext(ApplicationsContext);

    const changeApplication = (newApplication: string) => {
        setApplication?.(newApplication);
    }

    return {
        application,
        setApplication,
        changeApplication,
    };
};

export default useApplications;

