import {createTheme} from "@mui/material/styles";

const theme = createTheme({
    palette: {
        primary: {
            main: '#f2f4f5',
            light: '#fff',
            dark: '#a9aaab'
        },
        secondary: {
            main: '#d23731',
            light: '#db5f5a',
            dark: '#932622'
        },
        text: {
            primary: '#000',
            secondary: '#666666'
        },
        success: {
            main: '#1BA683',
            dark: '#12745b',
            light: '#48b79b'
        }
    },
    components: {
        MuiLink: {
            styleOverrides: {
                root: {
                    color: "black"
                }
            }
        },
    }
});

export default theme;