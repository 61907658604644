const Wrapper = {
    maxWidth: "1170px",
    margin: "0 auto",
    paddingLeft: "15px",
    paddingRight: "15px"
}
const Header = {
    display: "block",
    fontSize: "2em",
    marginBlockStart: "0.67em",
    marginBlockEnd: "0.67em",
    marginInlineStart: "0px",
    marginInlineEnd: "0px",
    fontWeight: "bold"
}

const InnerContent = {
    display: "block"
}

const Paragraph = {
    display: "block",
    marginBlockStart: "1em",
    marginBlockEnd: "1em",
    marginInlineStart: "0px",
    marginInlineEnd: "0px"
}

const List = {
    display: "block",
    listStyleType: "disc",
    marginBlockStart: "1em",
    marginBlockEnd: "1em",
    marginInlineStart: "0px",
    marginInlineEnd: "0px",
    paddingInlineStart: "40px"
}

const ListItem = {
    color: "#f42c09"
}

const Span = {
    color: "#000000"
}

const styles = {
    Wrapper: Wrapper,
    Header: Header,
    InnerContent: InnerContent,
    Paragraph: Paragraph,
    List: List,
    ListItem: ListItem,
    Span: Span
}

export default styles