import * as React from 'react';
import {useEffect} from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import {DateBirth, Password, PhoneNumber, Snils,} from "../../components/FormComponents";
import {Link} from "@mui/material";
import {checkFormBeforeSubmit} from "../../components/FormComponents/Controller";
import {useHistory} from "react-router-dom";
import {useSnackbar} from "notistack";
import FormField from "../../components/FormComponents/FormField";
import {checkEmail} from "../../components/FormComponents/Validations";
import {
    checkLoginNoBadSymbols,
    checkNoSpecialSymbols,
    checkPhone
} from "../../components/FormComponents/Validations/Validations";

import {useDispatch} from "react-redux";
import $api from "../../http";
import {PatientActionTypes} from "../../types/Store/patient";
import {Header} from "../../components";

const SignUpPage: React.FC = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const {enqueueSnackbar} = useSnackbar();
    const [blockConfirm, setBlockConfirm] = React.useState<boolean>(false);
    const [validForm, setValidForm] = React.useState<object>(
        {
            lastName: false,
            firstName: false,
            dateBirth: false,
            phoneNumber: false,
            login: false,
            password: false,
            repeatPassword: false,
            email: false
        }
    );

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);

        const reformatDate = (date: string) => {
            return new Date(date.split('.').reverse().join('-'));
        }

        const requestBody = {
            lastName: data.get('lastName'),
            firstName: data.get('firstName'),
            fatherName: data.get('middleName'),
            // @ts-ignore необходим чтобы ts не пиздел на то что дата может быть null, такого никогда не произойдет
            birthDay: reformatDate(data.get('dateBirth')),
            phoneNumber: data.get('phoneNumber'),
            snils: data.get('snils'),
            login: data.get('login'),
            password: data.get('password'),
            email: data.get('email')
        };

        if (!checkFormBeforeSubmit(validForm)) {
            setBlockConfirm(true);
            $api.post('auth/register',
                requestBody,
                {
                    headers: {'Content-Type': 'application/json'}
                }).then(async (response) => {
                if (response.status === 200) {
                    localStorage.setItem('token', response.data.token);

                    dispatch({type: PatientActionTypes.SET_CHILDREN_PATIENTS, payload: response.data.patients})
                    dispatch({type: PatientActionTypes.SET_PATIENT, payload: response.data.patients[0]});
                    setBlockConfirm(false);

                    history.push("/main/personal-cabinet");
                }
            }).catch((error) => {
                setBlockConfirm(false);
                enqueueSnackbar(error.response.data, {variant: "error"});
                console.error(error);
            });
        } else {
            setBlockConfirm(false);
            enqueueSnackbar('Не верно заполнена форма', {variant: "error"})
        }
    };

    useEffect(() => {
        document.title = "Регистрация";
    }, [])

    return (
        <>
            <Header></Header>
            <Container component="main" maxWidth="xs">
                <CssBaseline/>
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Typography component="h1" variant="h5">
                        {"Регистрация"}
                    </Typography>
                    <Box component="form" onSubmit={handleSubmit} noValidate sx={{mt: 1}}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                                <FormField
                                    label={"Фамилия"}
                                    additionalCheck={checkNoSpecialSymbols}
                                    required={true}
                                    validForm={validForm}
                                    setValidForm={setValidForm}
                                    name={"lastName"}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormField
                                    label={"Имя"}
                                    additionalCheck={checkNoSpecialSymbols}
                                    required={true}
                                    validForm={validForm}
                                    setValidForm={setValidForm}
                                    name={"firstName"}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <FormField
                                    label={"Отчество"}
                                    additionalCheck={checkNoSpecialSymbols}
                                    required={false}
                                    validForm={validForm}
                                    setValidForm={setValidForm}
                                    name={"middleName"}
                                    editAllowedEmpty={true}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <DateBirth
                                    required={true}
                                    validForm={validForm}
                                    setValidForm={setValidForm}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <PhoneNumber
                                    required={true}
                                    validForm={validForm}
                                    setValidForm={setValidForm}
                                    additionalCheck={checkPhone}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Snils required={false}/>
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <FormField
                                    label={"Логин"}
                                    additionalCheck={checkLoginNoBadSymbols}
                                    required={true}
                                    validForm={validForm}
                                    setValidForm={setValidForm}
                                    name={"login"}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <Password
                                    label={"Пароль"}
                                    required={true}
                                    validForm={validForm}
                                    setValidForm={setValidForm}
                                    name={"password"}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <Password
                                    label={"Повторите пароль"}
                                    required={true}
                                    validForm={validForm}
                                    setValidForm={setValidForm}
                                    name={"repeatPassword"}
                                    repeatPassword
                                />
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <FormField
                                    label={"Email"}
                                    required={true}
                                    validForm={validForm}
                                    setValidForm={setValidForm}
                                    additionalCheck={checkEmail}
                                    name={"email"}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Button
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    color="secondary"
                                    disabled={checkFormBeforeSubmit(validForm) || blockConfirm}
                                >
                                    {"Подтвердить"}
                                </Button>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Link underline="none" color="inherit" href={"/sign-in"}>
                                    <Button
                                        fullWidth
                                        variant="outlined"
                                        color="secondary"
                                    >
                                        {"Отмена"}
                                    </Button>
                                </Link>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Container>
        </>
    );
}

export default SignUpPage;