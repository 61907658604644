import React from "react";
import {MainPage, SettingsPage, SignInPage, SignUpPage, CovidInfoPage} from "../pages";
import EsiaAuth from "../pages/AuthEsiaPage";
import ForgotPasswordPage from "../pages/ForgotPasswordPage";
import {Appointment, Cabinet} from "../components";
import {PrivacyPolicy, TermsOfUse} from "../modules/LegalInformation";
import Centers from "../components/Appointment/Centers";
import Directions from "../components/Appointment/Directions";
import ListDoctors from "../components/ListDoctors/ListDoctors";

export interface IRoute {
    path: string;
    component: React.ComponentType;
    exact?: boolean,
    sensitive?: boolean,
    strict?: boolean
}

export enum RouteNames {
    SIGNIN = '/sign-in',
    SIGNUP = '/sign-up',
    ESIA = '/esia',
    FORGOT_PASSWORD = '/forgot-password',
    MAIN = '/main',
    APPOINTMENT = '/main/appointment',
    PERSONAL_CABINET = '/main/personal-cabinet',
    DOCTORS = '/main/doctors',
    PRIVACY_POLICY = '/main/privacy-policy',
    TERMS_OF_USE = '/main/terms-of-use',
    SETTINGS_PAGE = '/main/settings',
    ROOT = '/main',
    COVID = '/covid/:serviceId/:patientId',
}

export const publicRoutes: IRoute[] = [
    {path: RouteNames.SIGNIN, exact: true, component: SignInPage},
    {path: RouteNames.SIGNUP, exact: true, component: SignUpPage},
    {path: RouteNames.ESIA, exact: true, component: EsiaAuth},
    {path: RouteNames.FORGOT_PASSWORD, exact: true, component: ForgotPasswordPage},
    {path: RouteNames.MAIN, exact: false, component: MainPage},
    {path: RouteNames.COVID, exact: true, component: CovidInfoPage}
]

export const privateRoutes: IRoute[] = [
    {path: RouteNames.ROOT, exact: false, component: MainPage},
]

export const mainPrivateRoutes: IRoute[] = [
    {path: RouteNames.APPOINTMENT, exact: false, component: Appointment},
    {path: RouteNames.PERSONAL_CABINET, component: Cabinet},
    {path: RouteNames.PRIVACY_POLICY, exact: true, component: PrivacyPolicy},
    {path: RouteNames.TERMS_OF_USE, exact: true, component: TermsOfUse},
    {path: RouteNames.SETTINGS_PAGE, exact: true, component: SettingsPage},
]

export const mainPublicRoutes: IRoute[] = [
    {path: RouteNames.APPOINTMENT, exact: false, component: Appointment},
    {path: RouteNames.PRIVACY_POLICY, exact: true, component: PrivacyPolicy},
    {path: RouteNames.TERMS_OF_USE, exact: true, component: TermsOfUse},
]

export enum AppointmentRoutes {
    CENTERS = '/main/appointment',
    DIRECTIONS = '/main/appointment/directions',
    SPECIALISTS = '/main/appointment/specialists',
}

export const appointmentRoutes: IRoute[] = [
    {path: AppointmentRoutes.CENTERS, exact: true, component: Centers},
    {path: AppointmentRoutes.DIRECTIONS, exact: true, component: Directions},
    {path: AppointmentRoutes.SPECIALISTS, exact: true, component: ListDoctors},
]