import {Dispatch} from "redux";
import {OtherDocumentsAction, OtherDocumentsActionTypes} from "../../../types/Store/otherDocuments";
import $api from "../../../http";

const fetchOtherDocuments = (patientId: string) => {
    return async (dispatch: Dispatch<OtherDocumentsAction>) => {
        try {
             dispatch({type: OtherDocumentsActionTypes.FETCH_OTHER_DOCUMENTS});

             const response = await $api.get(`/appointment/findAllPatientFiles?patientId=${patientId}`);

             if (response.status === 200) {
                 dispatch({
                     type: OtherDocumentsActionTypes.FETCH_OTHER_DOCUMENTS_SUCCESS,
                     payload: response.data
                 })
             } else {
                 dispatch({
                     type: OtherDocumentsActionTypes.FETCH_OTHER_DOCUMENTS_ERROR,
                 })
             }
        } catch (error) {
            console.error(error);

            dispatch({
                type: OtherDocumentsActionTypes.FETCH_OTHER_DOCUMENTS_ERROR,
            });
        }
    }
}

export default fetchOtherDocuments;