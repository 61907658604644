import React from "react";
import {Avatar, Box, ListItemButton, ListItemIcon, ListItemText} from "@mui/material";
import {ExpandLess, ExpandMore} from "@mui/icons-material";
import {useTypedSelector} from "../../../../../hooks/useTypedSelector";
import ListUsers from "./ListUsers";

const UserAvatar: React.FC = () => {

    const patientState = useTypedSelector(state => state.patientState)
    const [open, setOpen] = React.useState<boolean>(false)

    const handleClick = () => {
        setOpen(!open)
    }

    return (
        <div>
            <ListItemButton onClick={handleClick}>
                <ListItemIcon>
                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            border: 2,
                            borderStyle: "dashed",
                            borderRadius: "50%",
                            p: 0.1,
                        }}
                    >
                        <Avatar
                            sx={{backgroundColor: "#666666"}}>
                            {patientState.patient.lastName[0] + patientState.patient.firstName[0]}
                        </Avatar>
                    </Box>
                </ListItemIcon>
                <ListItemText
                    primary={`${patientState.patient.lastName} ${patientState.patient.firstName[0]}. ${patientState.patient.fatherName ? patientState.patient.fatherName[0] + '.' : ''}`}
                />
                {patientState.childrenPatients.length > 1 ? (
                    open ? <ExpandLess/> : <ExpandMore/>
                ): (
                    <></>
                )}

                <ListUsers
                    patientState={patientState}
                    open={open}
                />
            </ListItemButton>
        </div>
    )
}

export default UserAvatar;