type stateErrorMessage = {
    correctCurrent: boolean,
    message: string
}

const checkDate = (date: string) => {
    if (date.length === 10) {
        return {
            correctCurrent: true,
            message: ""
        }
    } else {
        return {
            correctCurrent: false,
            message: `Не верно указана дата`
        }
    }
}

const checkEmptyField = (fieldValue: string, someValidFunction?: (value: string) => stateErrorMessage) => {

    if (fieldValue) {
        return someValidFunction ? someValidFunction(fieldValue) : {correctCurrent: true, message: ""}
    } else {
        return {correctCurrent: false, message: "Не может быть пустым"}
    }
}

const checkAllowedEmptyField = (fieldValue: string, someValidFunction?: (value: string) => stateErrorMessage) => {
    if (fieldValue) {
        return someValidFunction ? someValidFunction(fieldValue) : {correctCurrent: true, message: ""}
    } else {
        return {correctCurrent: true, message: ""}
    }
}

const checkEmail = (email: string) => {
    const reg = /^([a-z0-9]+(?:[._-][a-z0-9]+)*)@([a-z0-9]+(?:[.-][a-z0-9]+)*\.[a-z]{2,})$/i
    const test = reg.test(email)

    if (test) {
        return {
            correctCurrent: true,
            message: ""
        }
    } else {
        return {
            correctCurrent: false,
            message: `Не верно указана почта`
        }
    }
}

const checkPhone = (phone: string) => (phone.length !== 18) ? {
    correctCurrent: false,
    message: `Не верно указан номер телефона`
} : {
    correctCurrent: true,
    message: ""
}

const checkConfirmationCode = (code: string) => {
    const codeLength = 4;
    if (code.length === codeLength && code.replace(/\w/g,'').length === 0) {
        return {
            correctCurrent: true,
            message: ""
        }
    } else {
        return {
            correctCurrent: false,
            message: `Код подтверждения должен состоять из ${codeLength} цифр`
        }
    }
}

const checkNoSpecialSymbols = (value: string) => {
    const reg = /^[a-zA-Zа-яёА-ЯЁ\s-]+$/

    if(reg.test(value)) {
        return {
            correctCurrent: true,
            message: ""
        }
    } else {
        return {
            correctCurrent: false,
            message: 'Поле содержит недопустимые символы'
        }
    }
}

const checkLoginNoBadSymbols = (value: string) => {
    const reg = /^(?=.*$)[0-9А-ЯЁа-яёA-Za-z\d.-]{0,19}$/

    if(reg.test(value)) {
        return {
            correctCurrent: true,
            message: ""
        }
    } else {
        return {
            correctCurrent: false,
            message: 'Логин содержит недопустимые символы или его длина больше 19'
        }
    }
}

export {
    checkPhone,
    checkDate,
    checkEmptyField,
    checkAllowedEmptyField,
    checkEmail,
    checkConfirmationCode,
    checkNoSpecialSymbols,
    checkLoginNoBadSymbols
}