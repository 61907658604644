import React from "react";
import {
    Collapse,
    ListItemIcon,
    ListItemText,
    MenuItem, MenuList
} from "@mui/material";
import {Ballot, ExpandLess, ExpandMore, ListAlt} from "@mui/icons-material";
import {useHistory} from "react-router-dom";
import {RouteNames} from "../../router";
import {useDispatch} from "react-redux";
import {useTypedSelector} from "../../hooks/useTypedSelector";
import fetchAppointments from "../../store/action-creators/appointment";
import {MenuItemProps} from "./types";
import useApplications from "../../providers/ApplicationsProvider/useApplication";

const points = [
    {label: 'Все', value: 'all'},
    {label: 'Приёмы', value: 'receptions'},
    {label: 'Исследования', value: 'researches'},
    {label: 'Другие документы', value: 'otherDocuments'}
]

const AppointmentsItemMobile: React.FC<MenuItemProps> = (
    {
        handleCloseParent
    }
) => {
    const [open, setOpen] = React.useState<boolean>(false);
    const {changeApplication} = useApplications();

    const handleOpenAppointments = () => {
        setOpen(!open);
    }

    const history = useHistory();
    const dispatch = useDispatch();
    const patientState = useTypedSelector(state => state.patientState);

    const handleTest = (value: string) => {
        history.push(RouteNames.PERSONAL_CABINET);
        changeApplication(value);
        if (value !== "otherDocuments") {
            dispatch(fetchAppointments(value, patientState.patient.id))
        }
        handleCloseParent();
    }

    return (
        <div>
            <MenuItem onClick={handleOpenAppointments}>
                <ListItemIcon>
                    <Ballot/>
                </ListItemIcon>
                <ListItemText primary={'Мои записи'}/>
                {open ? <ExpandLess/> : <ExpandMore/>}

            </MenuItem>
            <Collapse in={open} timeout="auto" unmountOnExit>
                <MenuList dense>
                    {points.map((point, index) => (
                        <MenuItem key={index} sx={{pl: 4}} onClick={() => handleTest(point.value)}>
                            <ListItemIcon>
                                <ListAlt fontSize={"small"}/>
                            </ListItemIcon>
                            {point.label}
                        </MenuItem>
                    ))}
                </MenuList>
            </Collapse>
        </div>
    )
}

export default AppointmentsItemMobile;
