import React, {useLayoutEffect} from 'react';
import {useHistory} from "react-router-dom";
import $api from "../../http";
import {useSnackbar} from "notistack";

import {useDispatch} from "react-redux";
import {PatientActionTypes} from "../../types/Store/patient";

const EsiaAuth: React.FC = () => {
    const history = useHistory();
    let queryParams = new URLSearchParams(window.location.search);
    let code = queryParams.get("code");
    const {enqueueSnackbar} = useSnackbar();

    const dispatch = useDispatch();

    useLayoutEffect(() => {
        const esiaAuthRequest = async () => {
            await $api.post(
                `auth/authEsia`,
                {
                    code: code,
                    refreshToken: "",
                    redirectUri: "https://lk2.doctor-arbitailo.ru/esia"
                })
                .then((responseData) => {
                    if (responseData.status === 200) {
                        localStorage.setItem('token', responseData.data.token);
                        sessionStorage.setItem('isEsiaLogout', 'true');

                        dispatch({type: PatientActionTypes.SET_CHILDREN_PATIENTS, payload: responseData.data.patients})
                        dispatch({type: PatientActionTypes.SET_PATIENT, payload: responseData.data.patients[0]});

                        history.push("/main/personal-cabinet");
                    }
                }).catch((error) => {
                    enqueueSnackbar(error.response.data, {variant: "error"});
                    console.error(error);
                });
        }

        esiaAuthRequest().then();
    }, [history, dispatch, code, enqueueSnackbar]);

    return (
        <div style={{width: '100%'}}>
        </div>
    )
}

export default EsiaAuth;