import {DoctorsListAction, DoctorsListActionTypes, DoctorsListState} from "../../types/Store/doctorsList";
import * as _ from "lodash";

const initState: DoctorsListState = {
    doctorsList: [],
    loading: false,
    refresh: false,
    bookingDates: []
};

const doctorsListReducer = (state = initState, action: DoctorsListAction): DoctorsListState => {
    switch (action.type) {
        case DoctorsListActionTypes.FETCH_DOCTORS_LIST: {
            return {...state, loading: true};
        }
        case DoctorsListActionTypes.FETCH_DOCTORS_LIST_SUCCESS: {
            return {...state, loading: false, doctorsList: action.payload};
        }
        case DoctorsListActionTypes.FETCH_DOCTORS_LIST_ERROR: {
            return {...state, loading: false};
        }
        case DoctorsListActionTypes.PROCESS_NEW_BOOKING_STATE: {
            state.bookingDates.push(action.payload);

            return {...state};
        }
        case DoctorsListActionTypes.REMOVE_BOOKING_STATE: {
            _.remove(state.bookingDates, {...action.payload, reserved: true});

            return {...state};
        }
        case DoctorsListActionTypes.REFRESH_BOOKING_STATE: {
            return {...state, doctorsList: action.payload};
        }
        default: {
            return state;
        }
    }
}

export default doctorsListReducer;