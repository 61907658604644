import { Speciality } from "../Speciality"

export enum SpecialtiesListActionType {
    FETCH_SPECIALITIES_LIST = 'FETCH_SPECIALITIES_LIST',
    FETCH_SPECIALITIES_LIST_SUCCESS = 'FETCH_SPECIALITIES_LIST_SUCCESS',
    FETCH_SPECIALITIES_LIST_ERROR = 'FETCH_SPECIALITIES_LIST_ERROR'
}

type FetchSpecialtiesListAction = {
    type: SpecialtiesListActionType.FETCH_SPECIALITIES_LIST
}

type FetchSpecialtiesListSuccessAction = {
    type: SpecialtiesListActionType.FETCH_SPECIALITIES_LIST_SUCCESS
    payload: Array<Speciality>
}

type FetchSpecialtiesListErrorAction = {
    type: SpecialtiesListActionType.FETCH_SPECIALITIES_LIST_ERROR
}

export type SpecialtiesListAction = FetchSpecialtiesListAction |
    FetchSpecialtiesListSuccessAction | FetchSpecialtiesListErrorAction


export type SpecialtiesListState = {
    specialties: Array<Speciality>;
    loading: boolean
}