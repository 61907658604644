import {OtherDocumentsAction, OtherDocumentsActionTypes, OtherDocumentsState} from "../../types/Store/otherDocuments";

const initState: OtherDocumentsState = {
    docs: [],
    loading: false,
}

const otherDocumentsReducer = (state = initState, action: OtherDocumentsAction): OtherDocumentsState => {
    switch (action.type) {
        case OtherDocumentsActionTypes.FETCH_OTHER_DOCUMENTS:
            return {
                ...state,
                loading: true,
            }
        case OtherDocumentsActionTypes.FETCH_OTHER_DOCUMENTS_SUCCESS:
            return {
                ...state,
                docs: action.payload,
                loading: false,
            }
        case OtherDocumentsActionTypes.FETCH_OTHER_DOCUMENTS_ERROR:
            return {
                ...state,
                loading: false
            }
        default:
            return state
    }
}

export default otherDocumentsReducer;