/**
 * Функция для форматирования даты.
 * @param date
 * @param format
 *
 * @example
 * const exampleDate = new Date('May 17, 2022');
 * formDate(exampleDate, 'dd.mm.yy');
 * // => '17.05.2022'
 *
 * formDate(exampleDate, 'dd/mm/yy');
 * // => '17/05/2022'
 *
 * formDate(exampleDate, 'yy-mm-dd');
 * // => '2022-05-17'
 *
 */
const formatDate = (date: Date, format: string): string => {
    const map = {
        mm: (date.getMonth() + 1).toString().padStart(2, '0'),
        dd: date.getDate().toString().padStart(2, '0'),
        yy: date.getFullYear().toString(),
        H: date.getHours().toString().padStart(2, '0'),
        M: date.getMinutes().toString().padStart(2, '0'),
        S: date.getSeconds().toString().padStart(2, '0')
    };

    //@ts-ignore
    return format.replace(/mm|dd|yy|H|M|S/gi, (matched: keyof (typeof map)) => map[matched]);
}

export default formatDate;