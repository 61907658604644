import {Center} from "../Center";

export enum CentersActionTypes {
    FETCH_CENTERS = 'FETCH_CENTERS',
    FETCH_CENTERS_SUCCESS = 'FETCH_CENTERS_SUCCESS',
    FETCH_CENTERS_ERROR = 'FETCH_CENTERS_ERROR'
}

type FetchCentersAction = {
    type: CentersActionTypes.FETCH_CENTERS
}

type FetchCentersSuccessAction = {
    type: CentersActionTypes.FETCH_CENTERS_SUCCESS
    payload: Array<Center>
}

type FetchCentersErrorAction = {
    type: CentersActionTypes.FETCH_CENTERS_ERROR
}

export type CentersAction = FetchCentersAction |
    FetchCentersSuccessAction | FetchCentersErrorAction

export type CentersState = {
    centers: Array<Center>
    loading: boolean
}