import React from "react";
import {Grid} from "@mui/material";
import CardCustom from "./Card"
import EmptyDataCustom from "../../Customs/EmptyDataCustom";
import {useSnackbar} from 'notistack';
import {useTypedSelector} from "../../../hooks/useTypedSelector";
import {useDispatch} from "react-redux";
import {Appointment} from "../../../types/Appointment";
import fetchAppointments from "../../../store/action-creators/appointment";
import CircularLoader from "../../CircularLoader";
import "../../../styles/PersonCabinet/PatientRecords.scss"
import OtherDocuments from "./OtherDocuments";

interface SelectedTabProps {
    value: string;
}

const CheckAll = (props: SelectedTabProps) => {
    if (props.value === "otherDocuments") {
        return (
            <OtherDocuments/>
        )
    } else {
        return (
            <All value={props.value}/>
        )
    }
}

const All = (props: SelectedTabProps) => {
    const type = props;
    const [info, setInfo] = React.useState<Array<Appointment>>([]);
    const {enqueueSnackbar} = useSnackbar();

    const dispatch = useDispatch()

    const appointmentsState = useTypedSelector(state => state.appointment)
    const patientState = useTypedSelector(state => state.patientState)

    const changeSingleAppointment = (changedAppointment: Appointment) => {
        const indexAppointment = info.findIndex((value) => {
            return value.id === changedAppointment.id;
        });

        setInfo([
            ...info,
            info[indexAppointment] = changedAppointment
        ]);
    }

    React.useEffect(() => {
        dispatch(fetchAppointments(type.value, patientState.patient.id))
    }, [
        appointmentsState.refresh,
        enqueueSnackbar, type.value,
        patientState.patient.id,
        dispatch
    ])

    if (appointmentsState.loading) {
        return (
            <CircularLoader/>
        )
    } else if (!appointmentsState.appointments.length) {
        return (
            <EmptyDataCustom/>
        )
    } else {
        return (
            <Grid container>
                {appointmentsState.appointments.map((appointment, index) => (
                    <CardCustom
                        key={index}
                        appointment={appointment}
                        changeSingleAppointment={changeSingleAppointment}
                    />
                ))}
            </Grid>

        )
    }
}

export default CheckAll;