import React from "react";
import {Grid, Stack} from "@mui/material";
import useListDoctorsLogic from "./hooks";
import CircularLoader from "../CircularLoader";
import EmptyDataCustom from "../Customs/EmptyDataCustom";
import {CustomFilter} from "../../helpers";
import CardDoctor from "./CardDoctor";
import DialogCustom from "../Customs/DialogCustom";
import DialogUnauthorized from "../Customs/DialogUnauthorized";
import {AppointmentContext} from "../Appointment/Appointment";
import Search from "../Search";

const ListDoctors: React.FC = () => {

    const listDoctorsLogic = useListDoctorsLogic();
    const [searchString, setSearchString] = React.useState('');

    // eslint-disable-next-line
    const [selectedTab, setSelectedTab] = React.useContext(AppointmentContext);

    React.useLayoutEffect(() => {
        setSelectedTab('specialists');
        sessionStorage.setItem('selectedTab', 'specialists');
    })

    if (listDoctorsLogic.doctorsListState.loading) {
        return (
            <CircularLoader/>
        )
    } else if (!listDoctorsLogic.doctorsListState.doctorsList.length) {
        return (
            <EmptyDataCustom/>
        )
    } else {
        return (
            <div>
                <Stack alignItems={"center"} justifyItems={"center"}>
                    <Search setSearchString={setSearchString}/>
                </Stack>
                <Grid container spacing={1} mt={3}>
                    {CustomFilter(searchString, listDoctorsLogic.doctorsListState.doctorsList).map((doctor, index) => (
                        <CardDoctor
                            key={index}
                            doctor={doctor}
                            checkBookingDate={listDoctorsLogic.checkBookingDate}
                            selectedDate={listDoctorsLogic.date.selectedDate}
                            selectedDoctor={listDoctorsLogic.doctor.selectedDoctor}
                            handleChangeSelectedDate={listDoctorsLogic.handleChangeSelectedDate}
                            changeBookingState={listDoctorsLogic.changeBookingState}
                            setSelectedTime={listDoctorsLogic.timeSlot.setSelectedTimeSlot}
                            setSelectedDoctor={listDoctorsLogic.doctor.setSelectedDoctor}
                            setOpenAuthorized={listDoctorsLogic.authorizedDialog.setOpenAuthorized}
                            setOpenUnauthorized={listDoctorsLogic.unauthorizedDialog.setOpenUnauthorized}
                        />
                    ))}
                    <DialogCustom
                        open={listDoctorsLogic.authorizedDialog.openAuthorized}
                        setOpen={listDoctorsLogic.authorizedDialog.setOpenAuthorized}
                        dialogTitle="Подтверждение записи на прием"
                        dialogText={listDoctorsLogic.generateDialogBodyText()}
                        onAgreeClick={listDoctorsLogic.agreed}
                        unblockReservationTime={listDoctorsLogic.changeBookingState}
                        selectedTime={listDoctorsLogic.timeSlot.selectedTimeSlot ? listDoctorsLogic.timeSlot.selectedTimeSlot : ""}
                        selectedDoctorId={listDoctorsLogic.doctor.selectedDoctor ? listDoctorsLogic.doctor.selectedDoctor.id : ""}
                        selectedDate={listDoctorsLogic.date.selectedDate.split("|")[0]}
                    />
                    <DialogUnauthorized
                        open={listDoctorsLogic.unauthorizedDialog.openUnauthorized}
                        setOpen={listDoctorsLogic.unauthorizedDialog.setOpenUnauthorized}
                        dialogTitle="Подтверждение записи на прием"
                        selectedDate={listDoctorsLogic.date.selectedDate.split("|")[0]}
                        selectedMisId={listDoctorsLogic.doctor.selectedDoctor ? listDoctorsLogic.doctor.selectedDoctor.misid : ""}
                        selectedMisName={listDoctorsLogic.doctor.selectedDoctor ? listDoctorsLogic.doctor.selectedDoctor.misaddress : ""}
                        selectedDocId={listDoctorsLogic.doctor.selectedDoctor ? listDoctorsLogic.doctor.selectedDoctor.id : ""}
                        selectedDocName={listDoctorsLogic.doctor.selectedDoctor ? listDoctorsLogic.doctor.selectedDoctor.fullName : ""}
                        selectedTime={listDoctorsLogic.timeSlot.selectedTimeSlot ? listDoctorsLogic.timeSlot.selectedTimeSlot : ""}
                        unblockReservationTime={listDoctorsLogic.changeBookingState}/>
                </Grid>
            </div>
        )
    }
}

export default ListDoctors;