import React from "react";
import {Grid, Avatar} from "@mui/material";
import User from "./User";
import DateField from "./Date";
import Feedback from "./Feedback";
import {useTypedSelector} from "../../../../hooks/useTypedSelector";
import {CardCustomFC} from "../../../../types/components";
import {formatDate} from "../../../../helpers";

const CardCustom: React.FC<CardCustomFC> = (
    {
        appointment,
        changeSingleAppointment
    }
) => {
    const selectedPatient = useTypedSelector(state => state.patientState.patient)

    return (
        <Grid item xs={12} sm={12} md={12}>
            <div className={'record'}>
                <Avatar className={'record__avatar'}>
                    {selectedPatient ? (selectedPatient.lastName[0] + selectedPatient.firstName[0]) : ""}
                </Avatar>
                <div className={'box__shadow record__shadow'}>
                    <div className={'record__inner'}>
                        <User
                            appointment={appointment}
                        />
                        <div className={'record__feedback_and_date'}>
                            <DateField
                                date={formatDate(new Date(appointment.date), 'dd.mm.yy')}
                                time={appointment.time}
                            />
                            <Feedback
                                appointment={appointment}
                                changeSingleAppointment={changeSingleAppointment}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </Grid>
    )
}

export default CardCustom;