import React, {useState} from "react";
import {Grid, Stack} from "@mui/material";
import EmptyDataCustom from "../../Customs/EmptyDataCustom";
import {CustomFilter} from "../../../helpers";
import {useDispatch} from "react-redux";
import {useTypedSelector} from "../../../hooks/useTypedSelector";
import fetchCenters from "../../../store/action-creators/centers";
import CircularLoader from "../../CircularLoader";
import Item from "./Item";
import {Center} from "../../../types/Center";
import './style.scss';
import Search from "../../Search";
import {useNavigateAppointment} from "../hooks/useNavigateAppointment";
import {AppointmentContext} from "../Appointment";

const Centers: React.FC = () => {

    const dispatch = useDispatch();
    const centersList = useTypedSelector(state => state.centersList);
    const selectedCenter = sessionStorage.getItem('selectedCenter') || '*';
    const [searchString, setSearchString] = useState('');

    // eslint-disable-next-line
    const [selectedTab, setSelectedTab] = React.useContext(AppointmentContext);


    const navigateAppointment = useNavigateAppointment();

    React.useLayoutEffect(() => {
        setSelectedTab('centers');
        sessionStorage.setItem('selectedTab', 'centers');
    })

    React.useEffect(() => {
        dispatch(fetchCenters());
    }, [dispatch])

    const selectCard = (event: React.SyntheticEvent, center: Center) => {
        setSelectedTab('directions');
        sessionStorage.setItem('selectedTab', 'directions');
        sessionStorage.setItem('selectedCenter', center.id);
        sessionStorage.setItem('selectedDirection', '*');
        navigateAppointment.navigateDirections();
    }

    if (centersList.loading) {
        return (
            <CircularLoader/>
        )
    } else if (centersList.centers.length <= 0) {
        return (
            <EmptyDataCustom/>
        )
    } else {
        return (
            <div>
                <Stack alignItems={"center"} justifyItems={"center"}>
                    <Search setSearchString={setSearchString}/>
                </Stack>
                <Grid container spacing={5} mt={3}>
                    {CustomFilter(searchString, centersList.centers).map((mis, index) => (
                        <Item mis={mis} selectCenter={selectedCenter} key={index} selectCard={selectCard}/>
                    ))}
                </Grid>
            </div>

        )
    }
};

export default Centers;