import React from "react";
import {InputLabel, OutlinedInput} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import {IMaskInput} from "react-imask";

interface CustomProps {
    onChange: (event: { target: { name: string; value: string } }) => void;
    name: string;
}

interface SnilsFieldProps {
    required?: boolean,
    readOnly?: boolean,
    defaultValue?: string
}

const SnilsMaskCustom = React.forwardRef<HTMLElement, CustomProps>(

    function SnilsMaskCustom(props, ref) {
        const { onChange, ...other } = props;
        return (
            <IMaskInput
                {...other}
                //@ts-ignore
                mask="###-###-### ##"
                definitions={{
                    '#': /[0-9]/,
                }}
                //@ts-ignore
                inputRef={ref}
                onAccept={(value: any) => onChange({ target: { name: props.name, value } })}
                overwrite
            />
        );
    },
);

export default function Snils(props: SnilsFieldProps) {
    const { required, readOnly, defaultValue } = props;
    const [snils, setSnils] = React.useState<string>(defaultValue ?? "")

    const checkSnils = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSnils(event.target.value)
    }

    return (
        <FormControl variant="outlined" fullWidth>
            <InputLabel required={required} htmlFor="formatted-snils-mask-input" color="secondary" variant={"outlined"}>СНИЛС</InputLabel>
            <OutlinedInput
                value={snils}
                readOnly={readOnly}
                onChange={checkSnils}
                name="snils"
                id="formatted-snils-mask-input"
                inputComponent={SnilsMaskCustom as any}
                label="СНИЛС"
                placeholder={"XXX-XXX-XXX XX"}
                color="secondary"
            />
        </FormControl>
    )
}