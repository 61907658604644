import { SpecialtiesListState, SpecialtiesListAction, SpecialtiesListActionType } from "../../types/Store/specialtiesList"

const initState: SpecialtiesListState = {
    specialties: [],
    loading: false
}

const directionsListReducer = (state = initState, action: SpecialtiesListAction): SpecialtiesListState => {
    switch (action.type) {
        case SpecialtiesListActionType.FETCH_SPECIALITIES_LIST: {
            return {...state, loading: true}
        }
        case SpecialtiesListActionType.FETCH_SPECIALITIES_LIST_SUCCESS: {
            return {...state, loading: false, specialties: action.payload}
        }
        case SpecialtiesListActionType.FETCH_SPECIALITIES_LIST_ERROR: {
            return {...state, loading: false}
        }
        default: {
            return state
        }
    }
}

export default directionsListReducer;
