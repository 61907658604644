import React from "react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    IconButton,
    Stack, Typography
} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';

interface DialogProps {
    open: boolean,
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    dialogTitle: string,
    dialogText: string,
    handleClose?: (event: React.SyntheticEvent) => void,
    onAgreeClick?: (event: React.SyntheticEvent) => void;
    selectedTime?: string
    selectedDoctorId?: string,
    selectedDate?: string,
    unblockReservationTime?: (selectedDoctorId: string, selDate: string, reserved: boolean, time: string) => void;
}

const DialogCustom = (props: DialogProps) => {
    const { open, setOpen, dialogTitle, dialogText, handleClose, onAgreeClick, unblockReservationTime, selectedDoctorId, selectedDate, selectedTime } = props;

    const onCloseClick = () => {
        setOpen(false);

        if (unblockReservationTime && selectedDoctorId && selectedDate && selectedTime) {
            unblockReservationTime(selectedDoctorId, selectedDate, false, selectedTime);
        }
    }

    return (<Dialog
        BackdropProps={{ style: { opacity: 0.4 } }}
        fullWidth={true}
        maxWidth={'sm'}
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
    >
        <DialogTitle id="alert-dialog-title">
            <Stack>
                <Typography fontSize={14} fontWeight="bold" m={0}>
                    {dialogTitle}
                </Typography>
                <IconButton
                    aria-label="close"
                    onClick={onCloseClick}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </Stack>
        </DialogTitle>
        <DialogContent>
            <DialogContentText sx={{whiteSpace: "pre-line"}} id="alert-dialog-description">
                {dialogText}
            </DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button onClick={onAgreeClick} color={'secondary'} autoFocus>Да</Button>
            <Button onClick={onCloseClick} color={'info'}>
                Отмена
            </Button>
        </DialogActions>
    </Dialog>);
}


export default DialogCustom;