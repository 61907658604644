import {Dispatch} from "redux";
import {CentersAction, CentersActionTypes} from "../../../types/Store/centers";
import $api from "../../../http";

export const fetchCenters = () => {
    return async (dispath: Dispatch<CentersAction>) => {
        try {
            dispath({type: CentersActionTypes.FETCH_CENTERS});
            const response = await $api.get(`arbitailo/mis`);

            if (response.status === 200) {
                dispath({
                    type: CentersActionTypes.FETCH_CENTERS_SUCCESS,
                    payload: response.data
                });
            } else {
                dispath({
                    type: CentersActionTypes.FETCH_CENTERS_ERROR
                });
            }
        } catch (error) {
            console.error(error);

            dispath({
                type: CentersActionTypes.FETCH_CENTERS_ERROR
            });
        }
    }
}