import React, {useEffect} from "react";
import {Box, Container, Button, Tabs, Tab} from "@mui/material";
import {useTypedSelector} from "../../hooks/useTypedSelector";
import {useSnackbar} from "notistack";
import './style.scss';
import {useHistory} from "react-router-dom";
import {RouteNames} from "../../router";
import CheckAll from "./All";
import useApplications from "../../providers/ApplicationsProvider/useApplication";

const Cabinet: React.FC = () => {
    // const [selected, setSelected] = React.useState('all');

    const history = useHistory()

    const {application, changeApplication} = useApplications();

    const {enqueueSnackbar} = useSnackbar();
    const appointmentsState = useTypedSelector(state => state.appointment)
    const changeSelected = (event: React.SyntheticEvent, newSelected: string) => {
        if (!appointmentsState.loading) {
            changeApplication(newSelected);
            // setSelected(newSelected);
        } else {
            enqueueSnackbar('Подождите пока загрузятся текущие записи', {variant: "warning"})
        }
    };

    useEffect(() => {
        document.title = "Услуги";
    }, [])

    const handleClickAppointment = () => {
        history.push(RouteNames.APPOINTMENT)
    }

    return (
            <div className={'content-root'}>
                    <Container maxWidth={"xl"}>
                        <Box className={'tabs__and__button'}>
                            <Tabs className={"tabs"} value={application} onChange={changeSelected}>
                                <Tab className={"tab"} label={"Все"} value={"all"}/>
                                <Tab className={"tab"} label={"Приёмы"} value={"receptions"}/>
                                <Tab className={"tab"} label={"Исследования"} value={"researches"}/>
                                <Tab className={"tab"} label={"Прочие документы"} value={"otherDocuments"}/>
                            </Tabs>
                            <Button
                                variant="contained"
                                color="secondary"
                                disabled={appointmentsState.loading}
                                onClick={handleClickAppointment}
                            >
                                Запись
                            </Button>
                        </Box>
                    </Container>
                <div className={'content-card'}>
                    <div className={'content-box'}>
                        <div className={'content-scrollable-box'}>
                            <Container maxWidth="xl">
                                <CheckAll value={application as string}/>
                            </Container>
                        </div>
                    </div>
                </div>
            </div>
    )
}

export default Cabinet;