import {BookingState, Doctor} from "../Doctor";

export enum DoctorsListActionTypes {
    FETCH_DOCTORS_LIST = 'FETCH_DOCTORS_LIST',
    FETCH_DOCTORS_LIST_SUCCESS = 'FETCH_DOCTORS_LIST_SUCCESS',
    FETCH_DOCTORS_LIST_ERROR = 'FETCH_DOCTORS_LIST_ERROR',
    PROCESS_NEW_BOOKING_STATE = 'PROCESS_NEW_BOOKING_STATE',
    REMOVE_BOOKING_STATE = 'REMOVE_BOOKING_STATE',
    REFRESH_BOOKING_STATE = 'REFRESH_BOOKING_STATE'
}

type FetchDoctorsListAction = {
    type: DoctorsListActionTypes.FETCH_DOCTORS_LIST
};

type FetchDoctorsListSuccessAction = {
    type: DoctorsListActionTypes.FETCH_DOCTORS_LIST_SUCCESS
    payload: Array<Doctor>
};

type FetchDoctorsListErrorAction = {
    type: DoctorsListActionTypes.FETCH_DOCTORS_LIST_ERROR
};

type ProcessNewBookingStateAction = {
    type: DoctorsListActionTypes.PROCESS_NEW_BOOKING_STATE
    payload: BookingState
};

type RemoveBookingStateAction = {
    type: DoctorsListActionTypes.REMOVE_BOOKING_STATE
    payload: BookingState
};

type RefreshBookingStateAction = {
    type: DoctorsListActionTypes.REFRESH_BOOKING_STATE
    payload: Array<Doctor>
};

export type DoctorsListAction = FetchDoctorsListAction |
    FetchDoctorsListSuccessAction | FetchDoctorsListErrorAction |
    ProcessNewBookingStateAction | RemoveBookingStateAction
    | RefreshBookingStateAction;

export type DoctorsListState = {
    doctorsList: Array<Doctor>,
    loading: boolean,
    refresh: boolean,
    bookingDates: Array<BookingState>
};