import React from "react";
import {Card, Stack, CardContent} from "@mui/material";

const EmptyDataCustom = () =>
    // TODO: Возможно необходимо будет поменять стили
    <Card sx={{
        width: '100%',
        border: 3,
        borderColor: '#db5f5a',
    }}>
        <CardContent>
            <Stack direction={"row"}
                justifyContent="center"
                spacing={1}
            >
                НЕТ ДАННЫХ
            </Stack>
        </CardContent>
    </Card>

export default EmptyDataCustom;