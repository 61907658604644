import React from "react";
import {Menu} from "@mui/material";
import {AppointmentsItem, LogoutItem, SettingsItem} from "../../components/MenuItems";
import useLogout from "../../hooks/useLogout";

interface MenuI {
    anchorEl: null | HTMLElement,
    open: boolean,
    handleClose: () => void
}

const PersonalMenu: React.FC<MenuI> = (
    {
        anchorEl,
        handleClose,
        open
    }
) => {
    const handleLogout = useLogout();

    return (
        <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
                'aria-labelledby': 'basic-button',
            }}
        >
            <AppointmentsItem handleCloseParent={handleClose}/>
            <SettingsItem handleCloseParent={handleClose}/>
            <LogoutItem handleCloseParent={handleLogout}/>
        </Menu>
    )
}

export default PersonalMenu;