import React from "react";
import {Link, Stack} from "@mui/material";
import vk from "../../../images/vk.png";
import instagram from "../../../images/insta.png";

const SocialLink: React.FC = () => {
    return (
        <Stack
            direction={"row"}
            alignItems={"center"}
            justifyItems={"center"}
            spacing={1}
        >
            <Link
                underline="none"
                color="inherit"
                href={"https://vk.com/drarbitailo"}
                alignItems={"center"}
            >
                <img className={'icon'} src={vk} alt={"vk"}/>
            </Link>
            <Link underline="none" color="inherit" href={"https://instagram.com/doctor_arbitailo"}>
                <img className={'icon'} src={instagram} alt={"instagram"}/>
            </Link>
        </Stack>
    )
}

export default SocialLink;