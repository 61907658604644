import {CentersAction, CentersActionTypes, CentersState} from "../../types/Store/centers";

const initState: CentersState = {
    centers: [],
    loading: false
}

const centersReducer = (state = initState, action: CentersAction): CentersState => {
    switch (action.type) {
        case CentersActionTypes.FETCH_CENTERS: {
            return {...state, loading: true};
        }
        case CentersActionTypes.FETCH_CENTERS_SUCCESS: {
            return {...state, loading: false, centers: action.payload};
        }
        case CentersActionTypes.FETCH_CENTERS_ERROR: {
            return {...state, loading: false};
        }
        default: {
            return state;
        }
    }
}

export default centersReducer;