import {PaperProps, styled} from "@mui/material";
import Paper from "@mui/material/Paper";

interface StyledPaperProps extends PaperProps {
    selected?: boolean,
    height?: number | string,
    padding?: number | string,
    width?: number | string
}

const StyledPaper = styled(Paper, {
    shouldForwardProp: (prop) => prop !== 'success',
})<StyledPaperProps>(({selected, height, padding, width, theme}) => ({
    width: width,
    border: 'solid',
    borderWidth: 2,
    height: height,
    borderRadius: 12,
    padding: padding ?? 16,
    borderColor: selected ? theme.palette.secondary.main : '#FFF',
    transition: "all 500ms",
    '&:hover': {
        borderColor: theme.palette.secondary.light,
        opacity: 1,
    },
}))

export default StyledPaper;