import React from "react";
import {
    Divider,
    List,
} from "@mui/material";
import UserAvatar from "./UserAvatar";
import {AppointmentsItemMobile, LogoutItem, SettingsItem, SignInItem} from "../../../../../components/MenuItems";
import AppointmentItem from "../../../../../components/MenuItems/AppointmentItem";
import {MenuItemProps} from "../../../../../components/MenuItems/types";
import {useTypedSelector} from "../../../../../hooks/useTypedSelector";
import * as _ from "lodash";
import GuestAvatar from "./GuestAvatar";
import useLogout from "../../../../../hooks/useLogout";

const MenuList: React.FC<MenuItemProps> = (
    {
        handleCloseParent
    }
) => {

    const patientState = useTypedSelector(state => state.patientState)
    const handleLogout = useLogout();

    if (!_.isEmpty(patientState.patient)) {
        return (
            <List>
                <UserAvatar/>
                <Divider/>
                <AppointmentsItemMobile
                    handleCloseParent={handleCloseParent}
                />
                <AppointmentItem
                    handleCloseParent={handleCloseParent}
                />
                <SettingsItem
                    handleCloseParent={handleCloseParent}
                />
                <LogoutItem
                    handleCloseParent={handleLogout}
                />
            </List>
        )
    } else {
        return (
            <List>
                <GuestAvatar/>
                <Divider/>
                <SignInItem/>
                <AppointmentItem
                    handleCloseParent={handleCloseParent}
                />
            </List>
        )
    }
}

export default MenuList;