import React from "react";
import { ListItemIcon, MenuItem} from "@mui/material";
import {Settings} from "@mui/icons-material";
import {MenuItemProps} from "./types";
import {useHistory} from "react-router-dom";
import {RouteNames} from "../../router";

const SettingsItem: React.FC<MenuItemProps> = (
    {
        handleCloseParent
    }
) => {
    const history = useHistory()

    const handleClickItem = () => {
        history.push(RouteNames.SETTINGS_PAGE)
        handleCloseParent()
    }

    return (
        <MenuItem onClick={handleClickItem}>
            <ListItemIcon>
                <Settings/>
            </ListItemIcon>
            Настройки
        </MenuItem>
    )
}

export default SettingsItem;