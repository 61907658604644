import {styled, InputBase, InputBaseProps} from "@mui/material";

const StyledInputBase = styled(InputBase)<InputBaseProps>(() => ({
    input: {
        textAlign: "center"
    },
    marginRight: 3
}))

export default StyledInputBase;
