import React from "react";
import addCircleOutlineFilled from "../../../images/AddCircleOutlineFilled.png";
import {Link} from "@mui/material";

const SignInIcon: React.FC = () => {

    if (localStorage.getItem('token')) {
        return (
            <></>
        )
    } else {
        return (
            <Link
                className={'sign__icon'}
                underline="none"
                color="inherit"
                href={"/sign-in"}
            >
                <img
                    className={'sign__icon'}
                    src={addCircleOutlineFilled}
                    alt={"addCircleOutlineFilled"}
                />
            </Link>
        )
    }
}

export default SignInIcon;