import React from "react";
import SearchIcon from '@mui/icons-material/Search';
import {StyledInputBase} from "./StyledComponents";
import './style.scss';
import {Stack} from "@mui/material";

interface ISearch {
    setSearchString?: React.Dispatch<React.SetStateAction<string>> | undefined;
}

const Search = (props: ISearch) => {
    const {setSearchString} = props;

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (setSearchString !== undefined) {
            setSearchString(event.target.value);
        }
    };

    return (
        <Stack
            className={'search'}
            direction="row"
            alignItems={"center"}
            justifyItems={"center"}
        >
            <SearchIcon color={"action"}/>
            <StyledInputBase
                fullWidth={true}
                placeholder="Что вы ищите?"
                inputProps={{'aria-label': 'search arbitailo'}}
                onChange={handleChange}
            />
        </Stack>
    )
}

export default Search;