import {useDispatch} from "react-redux";
import {Patient} from "../../types/Patient";
import {PatientActionTypes} from "../../types/Store/patient";

const useChangePatient = () => {
    const dispatch = useDispatch()

    return (patient: Patient) => {
        dispatch({
            type: PatientActionTypes.SET_PATIENT,
            payload: patient
        })
    }
}

export default useChangePatient;