import React from "react";
import TextField from "@mui/material/TextField";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import {changeValidForm} from "./Controller";
import {Stack} from "@mui/material";
import ErrorMessage from "./ErrorMessage";
import * as _ from "lodash";

interface DateBirthFieldProps {
    defaultValue?: Date | null;
    readOnly?: boolean;
    required?: boolean;
    validForm?: object;
    setValidForm?: React.SetStateAction<object>;
    disabled?: boolean;
}

export default function DateBirth(props: DateBirthFieldProps) {
    const {required, validForm, setValidForm, readOnly, defaultValue, disabled} = props;

    const [dateBirth, setDateBirth] = React.useState<Date | null>(defaultValue ?? null);
    const [correct, setCorrect] = React.useState<boolean>(true);
    const [message, setMessage] = React.useState<string>("");

    const dateChange = (newValue: Date | null) => {
        if (required) {
            if (newValue === null) {
                setCorrect(false);
                setMessage("Не может быть пустым");
            } else if ((newValue as Date).toLocaleDateString('ru-ru').length !== 10) {
                setCorrect(false);
                setMessage("Дата заполнена некорректно");
            } else if (newValue > new Date()) {
                setCorrect(false);
                setMessage("Дата рождения больше текущей даты");
            } else {
                setCorrect(true);
                setMessage("");
                changeValidForm({
                    field: "dateBirth",
                    fieldValue: !!newValue,
                    validForm,
                    setValidForm
                });
            }
        }

        setDateBirth(newValue);
    };

    return (
        <DesktopDatePicker
            label="Дата рождения"
            value={dateBirth}
            disableFuture={true}
            mask={'__.__.____'}
            onChange={dateChange}
            readOnly={readOnly}
            OpenPickerButtonProps={{
                tabIndex: -1
            }}
            renderInput={
                (params: any) =>
                    <TextField
                        {..._.omit(params, ["InputProps", "inputProps"])}
                        name="dateBirth"
                        required={required}
                        disabled={disabled}
                        color={"secondary"}
                        error={!correct}
                        fullWidth
                        inputProps={{
                            ...params.inputProps,
                            placeholder: "дд.мм.гггг"
                        }}
                        InputProps={{
                            endAdornment: (
                                <Stack direction={"row"} justifyContent={"center"} alignItems={"center"}>
                                    {params.InputProps?.endAdornment}
                                    <ErrorMessage correct={correct} message={message}/>
                                </Stack>
                            ),
                        }}
                    />}
        />
    )
}