import { useMemo, useState, PropsWithChildren } from "react";
import {ApplicationsContextProps, ApplicationsContext} from "./ApplicationsContext";

export default function ApplicationsProvider<P>(props: PropsWithChildren<P>) {
    const { children } = props;

    const [application, setApplication] = useState<string>("all");

    const defaultProps = useMemo<ApplicationsContextProps>(
        () => ({
            application,
            setApplication,
        }),
        [application],
    );

    return (
        <ApplicationsContext.Provider value={defaultProps}>
            {children}
        </ApplicationsContext.Provider>
    );
}
