import React from "react";
import {Button, Container, Stack} from "@mui/material";
import {Route, Switch, useHistory} from "react-router-dom";
import {appointmentRoutes} from "../../router";
import {useCheckSelectedTab} from "./hooks/useCheckSelectedTab";
import {useAppointmentLogic} from "./hooks/useAppointmentLogic";
import {GlobalSetStateAction} from "../../types/Global";

type AppointmentContextType = [string, GlobalSetStateAction<string>];

export const AppointmentContext = React.createContext<AppointmentContextType>(['', () => '']);

const Appointment: React.FC = () => {

    const appointmentLogic = useAppointmentLogic();
    const checkSelectedTab = useCheckSelectedTab();
    const history = useHistory();

    return (
        <div className={'content-root'}>
            <Container maxWidth="xl">
                <Stack
                    direction="row"
                    justifyContent="space-between"
                >
                    <div className={'tabs'}>
                        <Button className={checkSelectedTab(appointmentLogic.tab.selectedTab, 'centers')}
                                onClick={appointmentLogic.handleClickCenters}>
                            Центры
                        </Button>
                        <Button className={checkSelectedTab(appointmentLogic.tab.selectedTab, 'directions')}
                                onClick={appointmentLogic.handleClickDirections}>
                            Направления
                        </Button>
                        <Button className={checkSelectedTab(appointmentLogic.tab.selectedTab, 'specialists')}
                                onClick={appointmentLogic.handleClickSpecialists}>
                            Специалисты
                        </Button>
                    </div>
                    {
                        localStorage.getItem('token') ?
                            <Button className={'appointment__button'} variant="contained"
                                    color="secondary"
                                    onClick={() => history.push('/main/personal-cabinet')}
                            >
                                Обратно в ЛК
                            </Button>

                            : <></>
                    }
                </Stack>
            </Container>
            <div className={'content-card'}>
                <div className={'content-box'}>
                    <div className={'content-scrollable-box'}>
                        <Container maxWidth="xl">
                            <AppointmentContext.Provider
                                value={[appointmentLogic.tab.selectedTab, appointmentLogic.tab.setSelectedTab]}>
                                <Switch>
                                    {appointmentRoutes.map((route) => (
                                        <Route
                                            key={route.path}
                                            path={route.path}
                                            exact={route.exact}
                                            component={route.component}
                                        />
                                    ))}
                                </Switch>
                            </AppointmentContext.Provider>
                        </Container>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default Appointment;