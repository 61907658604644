import React from "react";
import {Avatar, Box, ListItemButton, ListItemIcon, ListItemText} from "@mui/material";

const GuestAvatar: React.FC = () => {

    return (
        <ListItemButton>
            <ListItemIcon>
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        border: 2,
                        borderStyle: "dashed",
                        borderRadius: "50%",
                        p: 0.1,
                    }}
                >
                    <Avatar
                        sx={{backgroundColor: "#666666"}}
                    />
                </Box>
            </ListItemIcon>
            <ListItemText
                primary={`Гость`}
            />
        </ListItemButton>
    )
}

export default GuestAvatar;