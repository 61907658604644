import axios from "axios";
import {API_PATH} from "../constants/path";

const $api = axios.create({
    withCredentials: false,
    baseURL: API_PATH
});

$api.interceptors.request.use((config) => {
    config.headers = {
        ...config.headers,
        Authorization: `${localStorage.getItem("token")}`
    };

    return config;
});

$api.interceptors.response.use((config) => {
    return config;
}, async (error) => {

    // Если словили net::ERR_CONNECTION_REFUSED
    if(!error.response) {
        error.response = {
            data: "Не удалось подключиться к серверу"
        };

        return Promise.reject(error);
    }

    if (error.response.status === 500) {
        return Promise.reject(error);
    }

    if (error.response.status === 504) {
        error.response = {
            data: "Произошла ошибка во время попытки регистрации"
        };
        return Promise.reject(error);
    }

    const originalRequest = error.config;
    if (error.response.status === 401 && error.config && !error.config._isRetry) {
        try {
            originalRequest._isRetry = true;
            const response = await $api.post('/auth-help/extendToken', null, {
                headers: {
                    ...error.config.headers,
                    'isRefreshToken': 'true'
                }});

            if(response.status === 200) {
                localStorage.setItem('token', response.data.token);
                return $api.request(originalRequest);
            }

            if (response.status === 500) {
                originalRequest._isRetry = true;
                localStorage.clear();
                sessionStorage.clear();
                window.location.replace(`${window.location.protocol}//${window.location.host}/sign-in`);
            }
        } catch (error) {
            console.error(error);
            originalRequest._isRetry = false;
            localStorage.clear();
            sessionStorage.clear();
            window.location.replace(`${window.location.protocol}//${window.location.host}/sign-in`);
        }
    }

    if (error.response.status === 403 && error.config && !error.config._isRetry) {
        try {
            originalRequest._isRetry = true;
            localStorage.clear();
            sessionStorage.clear();
            window.location.replace(`${window.location.protocol}//${window.location.host}/sign-in`);
        } catch (error) {
            console.error(error);
        }
    }
});

export default $api;