import React from "react";
import {Switch, Route, Redirect} from "react-router-dom";
import {privateRoutes, publicRoutes, RouteNames} from "../../router";

const AppRouter: React.FC = () => {

    const auth = localStorage.getItem('token')

    return (
        auth ? (
            <Switch>
                {privateRoutes.map((route) =>
                    <Route
                        key={route.path}
                        path={route.path}
                        exact={route.exact}
                        component={route.component}
                        sensitive={route.sensitive}
                        strict={route.strict}
                    />
                )}
                <Redirect to={RouteNames.PERSONAL_CABINET}/>
            </Switch>
        ) : (
            <Switch>
                {publicRoutes.map((route) => (
                    <Route
                        key={route.path}
                        path={route.path}
                        exact={route.exact}
                        component={route.component}
                    />
                ))}
                <Redirect to={RouteNames.SIGNIN}/>
            </Switch>
        )
    )
}

export default AppRouter;