import React from "react";
import {MenuItemProps} from "./types";
import {ListItemIcon, MenuItem} from "@mui/material";
import {Logout} from "@mui/icons-material";
import {useDispatch} from "react-redux";
import {PatientActionTypes} from "../../types/Store/patient";

const LogoutItem: React.FC<MenuItemProps> = (
    {
        handleCloseParent
    }
) => {
    const dispatch = useDispatch();

    const exit = () => {
        localStorage.clear();
        dispatch({type: PatientActionTypes.CLEAR_PATIENT_STATE});
        handleCloseParent();
    }

    return (
        <MenuItem color={'secondary'} onClick={exit}>
            <ListItemIcon>
                <Logout/>
            </ListItemIcon>
            Выход
        </MenuItem>
    )
}

export default LogoutItem;