import {Client, IMessage} from "@stomp/stompjs";
import SockJS from "sockjs-client";
import {API_PATH} from "../../constants/path";

function getSocket() {
    return new SockJS(`${API_PATH}ws`);
}

const client = new Client({
    webSocketFactory: getSocket,
    // Если необходимо включить дебаг - раскомментируй эти строки.
    // debug: function (msg) {
    //     console.log(msg);
    // },
    reconnectDelay: 5000
});

const socketConnect = (destination: string, subscribeCallback: (message: IMessage) => void) => {
    client.onConnect = function (frame) {
        client.subscribe(destination, subscribeCallback);
    }

    client.activate();
}

function socketSend(destination: string, message: any) {
    client.publish({
        destination: destination,
        body: JSON.stringify(message)
    });
}

export {socketConnect, socketSend};